import React , { useState, useEffect } from "react";
import Main from "../../components/template/Main";
import { toast } from 'react-toastify';
import {FiX,FiEdit2} from 'react-icons/fi';
import {post,get, put,del} from '../../store/config/register';
import { useHistory,useParams} from "react-router-dom";
import PesquisaMorador from "../Pesquisa/PesquisaMorador";
import { empresa_Id } from "../../store/config/dadosuser";

const headerProps = {
    icon: 'comments',
    title: 'Cadastro de Grupos'
  }

  const initialState = {
    grupo: {
        id:0,
        nome:'',
        flg_Todos: false,
        empresa_Id:0
    }
  }

export default function CadastroGrupo() {
    const {id} = useParams();
    const history = useHistory();
    const [grupo,setGrupo]=useState(initialState.grupo);
    const [pessoas, setPessoas] = useState([]);
    const [pesquisa, setPesquisa] = useState({id: 0, descricao: ''});
    const [showPostPesquisa, setShowPostPesquisa] = useState(false);
    const [todos, setTodos] = useState(false);


    
    function updateFieldString(e){
        let gru = {...grupo};
        gru[e.target.name] =e.target.value;
        setGrupo({...gru})
    }

    async function BuscaPessoas (){
      let pes =await get('/GrupoPessoa/v1/Grupo/'+ id)

      if(pes !== undefined) {
        setPessoas(pes);
      }
    }

    async function VerificaUsuario(){
        grupo.empresa_Id = empresa_Id;
                
   }

   function cancelar() {
    history.push('/Avisos')
   }

  function clearPesquisa(){
    setPesquisa({id: 0, descricao: ''});
   }

   async function buscaDados() {
    if(id !== undefined) {
        let gru = await get('/Grupo/v1/'+ id);

        if (gru !== undefined) {
            setTodos(gru.flg_Todos);
            setGrupo(gru);
        }
    }
    
   }

   useEffect(() => {

    VerificaUsuario();
    buscaDados();
    BuscaPessoas ();

   },[]);

   function checkTodos(e){
    let gru ={...grupo};
    setTodos(e.target.value === 'true'? false : true);
    gru.flg_Todos =e.target.value === 'true'? false : true;

    setGrupo({...gru});
   }

   async function save() {
    if(id === undefined){
     const result =   await post('/Grupo/v1',grupo);
     await toast.success('Grupo salvo com sucesso.');
     history.push('/Grupo/Cadastro/'+result.id)
    } else {
        const result =   await put('/Grupo/v1/'+ id,grupo);
        await toast.success('Grupo alterado com sucesso.');
        history.push('/Grupo/Cadastro/'+result.id)
    }

   
   
   }

   async function AdicionarGrupoPessoa() { 

    if(pesquisa.id !== 0) {
       let gru = await get('/GrupoPessoa/v1/Grupo?grupo_id='+id+'&login_id='+pesquisa.id)

       let data ={grupo_Id: parseInt(id),login_Id: pesquisa.id};

       if(gru === null) {
        await post('/GrupoPessoa/v1',data)
       }else {
        await put('/GrupoPessoa/v1/'+ pesquisa.id,data)
       }

      await toast.success('Morador Adicionado ao Grupo.');

       clearPesquisa();
       BuscaPessoas();

    }

   }

   async function Delete(log_id){
    await del('/GrupoPessoa/v1/'+log_id+'?grupo_id='+id+'&login_id=' + log_id)
    toast.success('morador removido com sucesso.')
    BuscaPessoas();
   }

   function Edit(item) {
    setPesquisa({id: item.id,descricao: item.nome})
   }

   function updateFieldPesquisa(e){
    setPesquisa({...pesquisa, descricao: e.target.value});
   }

   function togglePostModal(item){
    item.empresaId =grupo.empresa_Id;
    setShowPostPesquisa(!showPostPesquisa) //trocando de true pra false
    if(item.id !== 0 && item.id !== undefined){
      let pes = {...pesquisa};
      pes.id =item.id;
      pes.descricao =item.nome;
      setPesquisa(pes);
    }
}

   function renderTable() {
    return (
        <div className='tabela'>
                <table>
                  <thead>
                    <tr>
                      <th scope="col" >Morador</th>
                      <th scope="col" >Unidade</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>

                  <tbody>
                    {pessoas === undefined ? null : pessoas.map((pessoa,index) => {
                      return (
                        <tr key={index}>
                            <td data-label="Morador">{pessoa.nome}</td>
                            <td data-label="unidade">{pessoa.unidade}</td>
                            <td  data-label="">
                            <button className="action" id="btn1" style={{backgroundColor: '#F99E08' ,marginRight: "10px" }} data-toggle="tooltip" data-placement="bottom" title="Editar" onClick={() => Edit(pessoa)}>
                              <FiEdit2 color="#FFF" size={17} />
                              </button>
                              <button className="action" style={{backgroundColor: '#ff0000',marginRight: "10px" ,marginTop: "10px" }} data-toggle="tooltip" data-placement="bottom" title="Excluir Espaço" onClick={()=> Delete(pessoa.id)} >
                              <FiX color="#FFF" size={17} />
                              </button>
                              </td>
                          </tr>
                      );
                    })}
                          


                  </tbody>
                </table>

        </div>
    );
}

    function renderForm() {
        return(
            <div className="main-form">
                <div className="container1">
                {/* <div className="checkbox">
                  <input
                    type="checkbox"
                    id={`custom-checkbox-0`}
                    name="todos"
                    value={todos}
                    checked={todos === true}
                    onChange={(e) => checkTodos(e)}
                  />
                <div className="right-section">Todos</div>
                </div> */}
                <label >Nome:</label>
                         <div>
                              <input type="text" id="nomeEspaco" name="nome" className="form-control"
                              value={grupo.nome} onChange={(e) => updateFieldString(e)} autoFocus/>
                         </div>
                         <hr/>
                         <div className="col-12 d-flex justify-content-start">
                              <button className="btn btn-warning ml-2"
                                   onClick={e => cancelar()}>
                                   Cancelar
                              </button>
                              <button className="btn btn-primary ml-2 salvar"
                                   onClick={e =>save()}>
                                   Salvar
                              </button>
                         </div>
                         {id !== undefined && (
                         <div>
                            <hr />
                         <label>Morador</label>
                            <div className="input-group mb-3 cli">
                            <input type="text" class="form-control" name="descricao"  value={pesquisa.descricao} placeholder="Pesquise um morador" aria-label="Recipient's username" aria-describedby="basic-addon2" onChange={e => updateFieldPesquisa(e)}/>
                            <div className="input-group-append">
                            <button className="btn btn-info" type="button"  onClick={() => togglePostModal(pesquisa)}>Pesquisar</button>
                            </div>
                </div>
                <div className="col-12 d-flex justify-content-start">
                <button className="btn btn-primary ml-2 salvar"
                                   onClick={e =>AdicionarGrupoPessoa()}>
                                   Adicionar
                              </button>
                              </div>
                              {showPostPesquisa && (
            <PesquisaMorador
            conteudo={pesquisa}
            close={togglePostModal}
            />
            )}
                <hr />
                         {renderTable()} 
                         </div>
                         
                         )}
                         
           
                        
                </div>
            </div>
        );
    }
    return (
        <Main {...headerProps}>
            {renderForm()}
        </Main>
    );
}