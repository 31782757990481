import React, {useState,useEffect} from 'react'
import { post, get } from '../../store/config/register';
import { toast } from 'react-toastify';
import { empresa_Id } from '../../store/config/dadosuser';
import {FiX} from 'react-icons/fi';
import QRCode  from "qrcode";

import { format, isDate, parse } from 'date-fns';

import email from '../../assets/imgs/email.png';
import whatsapp from '../../assets/imgs/whatsapp.png';
import email_cinza from '../../assets/imgs/email_cinza.png';
import whatsapp_cinza from '../../assets/imgs/whatsapp_cinza.png';

import './Qrcode.css'

export default function QrCode({conteudo,close}){

    const [visitante,setVisitante]=useState(conteudo);
    const [src,setSrc]=useState('');
    const [templateEmail,setTemplateEmail]= useState({});

    async function GerarQrcode(){
            QRCode.toDataURL(visitante.qrcode,{
              width: 320,
              height: 320
             }).then(setSrc);
               
      }


      useEffect(() =>{
        GerarQrcode();
        BuscaTemplate(empresa_Id);
      },[])
      

      async function BuscaTemplate(idEmpresa){
        let result =await get('/Template/v1?tipo=8&empresa='+idEmpresa);
       // console.log(result);
        setTemplateEmail(result);
        return result;
       }

      function downloadImage() {
        const img = new Image();
        img.crossOrigin = "anonymous";
        img.src = src;
        img.onload = () => {
          // create Canvas
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");
          canvas.width = img.width;
          canvas.height = img.height;
          ctx.drawImage(img, 0, 0);
          // create <a> tag
          const a = document.createElement("a");
          a.download = "download.png";
          a.href = canvas.toDataURL("image/png");    
          a.click();
        };
      }

      async function enviarWhatsapp(){

        const data ={base64: src.toString().replace('data:image/png;base64,',''), id: parseInt(visitante.qrcode) }
        let url =  await post('/Imagem/v1',data);


        // const contentType = 'image/png';
        // const blob = b64toBlob(src.replace('data:image/png;base64,',''), contentType);
        // const blobUrl = URL.createObjectURL(blob);

        let teste =parse(visitante.inicio,"yyyy-MM-dd'T'HH:mm:ss",new Date());
        if(isNaN(teste.getMonth())){
          teste = visitante.inicio;
          teste =format(parse(teste,'yyyy-MM-dd',new Date()),'dd/MM/yyyy');

        }else {
          teste =format(teste,'dd/MM/yyyy')
        }
         let encodedURL = encodeURIComponent(url);
         let message=`https://api.whatsapp.com/send?phone=55${visitante.celular}&text=Olá ${visitante.nome}, sua visita foi agendada para o dia  ${teste} por: ${visitante.anfitriao}, para acessar a piscina utilize o QRCode no link abaixo: ${encodedURL} - Condomínio Lavillette`;
         window.location.replace(message);
      }

      function dataURLtoFile(dataurl, filename) {
 
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), 
            n = bstr.length, 
            u8arr = new Uint8Array(n);
            
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        
        return new File([u8arr], filename, {type:mime});
    }

      const b64toBlob = (b64Data, contentType='', sliceSize=512) => {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];
      
        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
          const slice = byteCharacters.slice(offset, offset + sliceSize);
      
          const byteNumbers = new Array(slice.length);
          for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
          }
      
          const byteArray = new Uint8Array(byteNumbers);
          byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, {type: contentType});
        return blob;
      }

      async function enviarEmail(){
        let emails =[];
        emails.push(visitante.email);
        //emails.push('william@cisistemas.com.br');
      
        // const temp =await template(visitante)

         const dataimg ={base64: src.toString().replace('data:image/png;base64,',''), id: parseInt(visitante.qrcode) }
         let url =  await post('/Imagem/v1',dataimg);
        
         let encodedURL = encodeURIComponent(url);
        const unid= await get("/Unidade/v1/"+ visitante.unidade_id)
        visitante.unidade = unid.nome;

        let inicio = format(parse(visitante.inicio.toString().substring(0,10),"yyyy-MM-dd",new Date()),'dd/MM/yyyy' )

         templateEmail.template =templateEmail.template.replace('@nome',visitante.nome);
         templateEmail.template =templateEmail.template.replace('@anfitriao',visitante.anfitriao);
         templateEmail.template =templateEmail.template.replace('@unidade',visitante.unidade);
         templateEmail.template =templateEmail.template.replace('@data',inicio);
         templateEmail.template =templateEmail.template.replace('@link',url);
          
         let data =  { sender:emails, subject: templateEmail.assunto, html:  templateEmail.template , image: src.toString().replace('data:image/png;base64,',''), empresa_id: empresa_Id}
         let result =await post('/Email/v1',data);
         await toast.success('email enviado com sucesso');
      }

      async function template(usuario){
    
        const data ={base64: src.toString().replace('data:image/png;base64,',''), id: parseInt(visitante.qrcode) }
        let url =  await post('/Imagem/v1',data);
       
        let encodedURL = encodeURIComponent(url);
       const unid= await get("/Unidade/v1/"+ usuario.unidade_id)
       usuario.unidade = unid.nome;

       

        let temp=`<html>
        <head>
        <meta charset="utf-8">
        <meta name="viewport" content="width=device-width, initial-scale=1">
        <title>Troca de senha de usuário - Aplicativo do Condomínio - La Villete</title>
        </head>
		
         <body>
         <div bgcolor="#FFFFFF" marginwidth="0" marginheight="0">
			<center>
			
				<table width="640" border="0" cellpadding="0" cellspacing="0" align="center" bgcolor="#f5f5f5">
					<tr>
						<td>
							<table cellpadding="0" cellspacing="0" border="0" style="font-family:Calibri,sans-serif" width="100%">
							</table>

							<p>&nbsp;</p>
							
							 <table width="640" border="0" cellpadding="0" cellspacing="0" align="center" bgcolor="#f5f5f5">
								 <tr>
									 <td>
										 <table align="center" cellpadding="0" cellspacing="0" style="font-family:Calibri,sans-serif" width="720">
											 <tbody>
												 <tr>
													 <td style="padding:0px">
														 <center><a href="https://pt-br.facebook.com/condominiolavillette/" target="_blank" rel="noreferrer">
															 <img alt="Condomínio La Villette" src="https://condominio.cisuporte.com.br/imagens/logolavillete.png" width="50%" height="50%" 
															 style="display:block">
														 </a></center>
													 </td>
												 </tr>
											 </tbody>
										</table>
									</td>
								</tr>
										
								<tr>
									<td>								
										<h1 style="display:block;font-size:18px;color:#363636;margin:26px 15px;line-height:130%;font-family:Calibri,sans-serif;text-align:center;font-weight:400">
										 Olá ${usuario.nome},</h1>
										 <h2 style="display:block;font-size:18px;color:#363636;margin:0px 25px 0px;line-height:130%;font-family:Calibri,sans-serif;text-align:center;text-transform:uppercase;font-weight:900">
											 SUA VISITA FOI AGENDADA 
										 </h2>
										 <br />  
										 <h3 style="display:block;font-size:14px;color:#363636;margin:0px 25px 0px;line-height:130%;font-family:Calibri,sans-serif;text-align:center;text-transform:uppercase;font-weight:900">
										 por ${usuario.anfitriao} - ${usuario.unidade}
										 </h3>
										 <h3 style="display:block;font-size:16px;color:#363636;margin:0px 25px 0px;line-height:130%;font-family:Calibri,sans-serif;text-align:center;text-transform:uppercase;font-weight:900">
										 para o dia ${format(parse(usuario.inicio.toString().substring(0,10),"yyyy-MM-dd",new Date()),'dd/MM/yyyy' )}
										 </h3>
										 <p style="display:block;font-size:16px;color:#363636;margin:22px 25px;line-height:130%;font-family:Calibri,sans-serif;text-align:center">
										 Para acessar a piscina, utilize o QRcode abaixo<br>
										</p>
									</td>
								</tr>
								</table>
                <table width="640" border="0" cellpadding="0" cellspacing="0" align="center" bgcolor="#000000">
								<tr style="margin-top:25px">
									<td>
										<div style="margin-top:25px">
										</div>
									</td>
								</tr>
                <tr>
									<td>
										<center><a href="#" target="_blank" rel="noreferrer">
													 <img alt="QR Code - Condomínio La Villette" src="${url}" width="50%" height="50%" 
													 style="display:block">
												 </a>
									    </center>
                    </td>
								</tr>

								<tr style="margin-top:25px">
									<td>
										<div style="margin-top:25px">
										</div>
									</td>
								</tr>
										
							</table>
						</td>
					</tr>			
				</table>
				<p>
					<a href="https://acessoid.com.br/" target="_blank" rel="noreferrer"> Enviado por www.acessoid.com.br </a>
				</p>
			</center>
		</div>
	</body>
</html>`;
 
        return temp;
       }

    function render(){
        
        return (
            <div className="modalQrcode">
                <div className="containerQrcode">
                    <button className="closeQrcode" onClick={() =>{
                      close(visitante,visitante.id)
                      }}>
                        <FiX size={23} color="#FFF" />
                        Voltar
                    </button>
                    <div className='buttonSend'>
                        <img src={src} width={250} height={250} alt="Piscina" data-toggle="tooltip" data-placement="bottom" title="Precione para Compartilhar Qrcode"/>
                        <label style={{fontWeight: 'bold'}}>Compartilhar:</label>
                        <div >
                        <img src={visitante.email !== ''? email : email_cinza} width={60} height={60}  alt="Foto de perfil do usuario" style={{marginRight: '15px'}} data-toggle="tooltip" data-placement="bottom" title="Envio Email"  onClick={e =>visitante.email !== ''? enviarEmail():null} />
                        <img src={visitante.celular !== ''? whatsapp : whatsapp_cinza} width={60} height={60} med alt="Foto de perfil do usuario" data-toggle="tooltip" data-placement="bottom" title="Compartilhar via WhatsApp"  onClick={e =>visitante.celular !== ''?  enviarWhatsapp(): null} />                           
                        </div>     
                    </div>
                    </div>
            </div>
        );
    }
    return (
        <div>
            {render()}
        </div>
    );
}