import React ,{useEffect,useState}from "react";
import { useParams } from 'react-router-dom';
import QRCode  from "qrcode";

export default function QrCodeLeitura() {
    const {qrcode } =useParams(); 
    const [src,setSrc]=useState('');

    function GerarQrcode(){
            QRCode.toDataURL(qrcode,{
              width: 320,
              height: 320,
              margin: 0
             }).then(setSrc);
               
      }

      useEffect(()=>{
        GerarQrcode();
      },[])

    function render(){
        return(
            <div>
                 <img src={src} width={250} height={250} alt="Piscina" data-toggle="tooltip" data-placement="bottom" title="Precione para Compartilhar Qrcode"/>
            </div>
        );
    }
    return(
        <div>
            {render()}
        </div>
    );
}