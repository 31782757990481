import React, { useState,useEffect }  from "react";
import Main from "../../components/template/Main";
import {get,post,put} from '../../store/config/register';
import { toast } from 'react-toastify';
import { Tabs,Tab } from "react-bootstrap";
import { useHistory,useParams} from "react-router-dom";
import { format,parse } from "date-fns";
import Messagem from "../../components/mensagem/Messagem";
import HorarioPadrao from "./Horario/HorarioPadrao";
import HorarioEspecifico from "./Horario/HorarioEspecifico";
import Editor from '../Editor/Editor';
import { empresa_Id } from "../../store/config/dadosuser";
import './CadastroEspaco.css';
import 'font-awesome/css/font-awesome.min.css';

const headerProps = {
     icon: 'object-ungroup',
     title: 'Cadastro de Espaços'
}


const initialState={
     espaco:{
          id:0,
          nome: '',
          antecedencia_Minima: 0,
          antecedencia_Maxima:0,
          desistencia:0,
          capacidade: 0,
          taxa: 0.00,
          termo: false,
          tipo_Espaco_Id: 0,
          empresa_Id:empresa_Id,
          lista_Convidado: false,
          flg_Descricao_Evento: false,
          flg_Numero_Convidado:false,
          flg_Taxa_Reserva: false,
          flg_Controle_Periodo:'D',
          anexo_Pagamento:false,
          flg_Celular_Responsavel:false,
     },
     horaPeriodo: {
          id: 0,
          espaco_Id: 0,
          dia_Semana: 0,
          hora_Inicio: '08:00',
          hora_Fim: '18:00'     
         },
         diaSemana: {
          dom: false,
          seg: false,
          ter: false,
          qua: false,
          qui: false,
          sex: false,
          sab: false
         }

    }




export default function NovoEspaco () {
     const history = useHistory();
     const {id}=useParams();
     const [espaco,setEspaco] = useState(initialState.espaco);  
     const [tipoEspacos,setTipoEspacos]= useState([]);
     
   
   

     function CarregaAbas(){
          return (
            <Tabs defaultActiveKey={0}>
                <Tab title="Espaço" eventKey={0}>
                  {renderForm()}
                </Tab>
                <Tab title="Horário Funcionamento" eventKey={1} disabled ={id === undefined ? true  :false}>
                    <HorarioPadrao />
                </Tab>
                <Tab title={" Agenda"} icon={<i className="fal fa-tasks"></i> } eventKey={2} disabled ={id === undefined ? true  :false}>
                <HorarioEspecifico />
                </Tab>
                { id !== undefined && (
                    <Tab title="Documento" eventKey={3} >
                         <i className="fal fa-tasks"></i>
                 <Editor />
                </Tab>
                ) }
                
            </Tabs>
          );
        }

          async function GetTipoEspaco(){
             let tipo=  await get('/TipoEspaco/v1');
             if(tipo !== undefined){              
               
               tipo.unshift({id: 0, nome: ''})
               setTipoEspacos(tipo);
             }
          }

          async function CarregaDados(){
               if(id !== undefined){
                 const esp = await   get('/Espaco/v1/'+ id);

                 setEspaco({
                    id: esp.id,
                    nome: esp.nome,
                    antecedencia_Minima: esp.antecedencia_Minima,
                    antecedencia_Maxima: esp.antecedencia_Maxima,
                    desistencia: esp.desistencia,
                    capacidade: esp.capacidade,
                    taxa: esp.taxa,
                    termo: esp.termo,
                    tipo_Espaco_Id: esp.tipo_Espaco_Id,
                    empresa_Id:esp.empresa_Id,
                    lista_Convidado: esp.lista_Convidado,
                    flg_Descricao_Evento: esp.flg_Descricao_Evento,
                    flg_Numero_Convidado: esp.flg_Numero_Convidado,
                    flg_Taxa_Reserva: esp.flg_Taxa_Reserva,
                    flg_Controle_Periodo: esp.flg_Controle_Periodo,
                    anexo_Pagamento:  esp.anexo_Pagamento,
                 })
               }
          }

          function  updateFieldString(event){
               const esp ={...espaco}
               esp[event.target.name] =event.target.value
                    setEspaco(esp);
          }

          function  updateField(event){
               const esp ={...espaco}
               if(event.target.name === "taxa"){
                    esp[event.target.name] =parseFloat(event.target.value)
                    setEspaco(esp);
               }            
               else {
                    esp[event.target.name] =parseInt(event.target.value)
                    setEspaco(esp);
               }  
              
         }
      async function VerificaUsuario(){     
          espaco.empresa_id =empresa_Id;            
     }

     useEffect(()=>{
          VerificaUsuario();
          GetTipoEspaco();

          CarregaDados();
        
     },[])

     function renderTipoEspaco(){
          if(tipoEspacos !== undefined){
               return tipoEspacos.map((TipoEspaco)=> {
                    return (<option value={TipoEspaco.id} key={TipoEspaco.id}> {TipoEspaco.nome} </option>)
               })
          }
     }

     function trocaTipoEspaco(e) {
          setEspaco({...espaco, tipo_Espaco_Id: parseInt(e.target.value)})
      }


      function trocaTermo(e) {
          setEspaco({...espaco, lista_Convidado: e.target.value === "true" ? true : false})
      }

      function trocaPeriodo(e) {
          setEspaco({...espaco, flg_Controle_Periodo: e.target.value})
      }


     async function save(){
     
          let resp;
          if(id === undefined){             
               resp=  await post('/Espaco/v1',espaco)
          }else {
               resp =    await  put('/Espaco/v1/'+id,espaco)
          }
         await toast.success('Espaco Salvo com sucesso')
          history.push ('/NovoEspaco/'+ resp.id)   
      }

      function clear(){   
          setTipoEspacos({...initialState.espaco})
      }

      async function handleFile(e){
          if(e.target.files[0]){
            const fil = e.target.files[0];
            
            if(fil.type === 'application/pdf'){

                  let base64=await toBase64(fil);          
                  if (fil.type ==='application/pdf'){
                    setEspaco({...espaco,file: base64.replace('data:image/jpeg;base64,','')});
                  }else{
                    setEspaco({...espaco, file: base64.replace('data:image/png;base64,','')});
                  }

            }else{
              alert('Envie uma imagem do tipo PNG ou JPEG');
              return null;
            }
      
          }
      
        }

        const toBase64 = file => new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = error => reject(error);
      });

      

      function checkeddias(e){
          let esp={...espaco};
          esp[e.target.name]=e.target.value ==='true'? false : true;
          setEspaco({...esp})
        }

       

     function renderForm() {
          return (
               <div className="main-form">

                    <div className="container1">
                         <label >Nome do Espaço:</label>
                         <div>
                              <input type="text" id="nomeEspaco" name="nome" className="form-control"
                              value={espaco.nome} onChange={(e) => updateFieldString(e)} autoFocus/>
                         </div>

                         <label>Capacidade máxima:</label>
                         <div>
                              <input type='number' id="dias" name="capacidade" className="form-control"
                              value={espaco.capacidade}  onChange={(e) => updateField(e)}>
                              </input>
                         </div>

                         <label>Habilitar Lista de Convidados:</label>
                         <div>
                              <select id="check" value={espaco.lista_Convidado} className="form-control" onChange={(e)=> trocaTermo(e)}>
                                   <option value={false}>Não</option>
                                   <option value={true}>Sim</option>    
                              </select>
                         </div>

                         <label>Taxa de uso (R$):</label>
                         <div>
                              <input type='number' step='.01' id="taxa" name="taxa" className="form-control"
                              value={espaco.taxa}  onChange={(e) => updateField(e)}>
                              </input>
                         </div>

                         <label>Antecedência máxima para reserva (em dias): </label>
                         <div>
                              <input type="number" id="dias" name="antecedencia_Maxima" className="form-control"
                              value={espaco.antecedencia_Maxima}  onChange={(e) => updateField(e)}/>
                         </div>

                         <label>Antecedência mínima para reserva (em dias): </label>                         
                         <div>
                              <input type='number' id="dias" name="antecedencia_Minima" className="form-control"
                              value={espaco.antecedencia_Minima}  onChange={(e) => updateField(e)}> 
                              </input>
                         </div>

                         <label>Prazo para desistência (em horas):</label>
                         <div>
                              <input type='number' id="dias" name="desistencia" className="form-control"
                              value={espaco.desistencia}  onChange={(e) => updateField(e)}>
                              </input>
                         </div>

                         <label>Tipo de Agendamento:</label>
                         <div>
                          <select value={espaco.tipo_Espaco_Id} id="nomeEspaco" className="form-control" onChange={(e)=> trocaTipoEspaco(e)}>
                                  {renderTipoEspaco()}
                              </select>
                         </div>

                         <label>Campos Opcionais:</label>

                         <div className="checkbox">
                         <input
                              type="checkbox"
                              id={`custom-checkbox-${0}`}
                              name="flg_Descricao_Evento"
                              value={espaco.flg_Descricao_Evento}
                              checked={espaco.flg_Descricao_Evento === true}
                              onChange={(e) => checkeddias(e)}
                         />
                         <div className="right-section">Descrição Evento</div>
                         </div>
                         <div className="checkbox">
                         <input
                              type="checkbox"
                              id={`custom-checkbox-${0}`}
                              name="flg_Celular_Responsavel"
                              value={espaco.flg_Celular_Responsavel}
                              checked={espaco.flg_Celular_Responsavel === true}
                              onChange={(e) => checkeddias(e)}
                         />
                         <div className="right-section">Celular Responsável Evento</div>
                         </div>

                         <div className="checkbox">
                         <input
                              type="checkbox"
                              id={`custom-checkbox-${1}`}
                              name="flg_Numero_Convidado"
                              value={espaco.flg_Numero_Convidado}
                              checked={espaco.flg_Numero_Convidado === true}
                              onChange={(e) => checkeddias(e)}
                         />
                         <div className="right-section">Número de Convidado</div>
                         </div>
                         
                         <div className="checkbox">
                         <input
                              type="checkbox"
                              id={`custom-checkbox-${1}`}
                              name="flg_Taxa_Reserva"
                              value={espaco.flg_Taxa_Reserva}
                              checked={espaco.flg_Taxa_Reserva === true}
                              onChange={(e) => checkeddias(e)}
                         />
                         <div className="right-section">Taxa de Reserva</div>
                         </div>

                         <div className="checkbox">
                         <input
                              type="checkbox"
                              id={`custom-checkbox-${1}`}
                              name="anexo_Pagamento"
                              value={espaco.anexo_Pagamento}
                              checked={espaco.anexo_Pagamento === true}
                              onChange={(e) => checkeddias(e)}
                         />
                         <div className="right-section">Anexar Pagamento</div>
                         </div>
                         
                         <label>Controle de Agendamento no Período:</label>
                         <div>
                              <select id="check" value={espaco.flg_Controle_Periodo} className="form-control" onChange={(e)=> trocaPeriodo(e)}>
                                   <option value='D'>Dia</option>
                                   <option value='S'>Semana</option>
                                   <option value='M'>Mês</option>   
                              </select>
                         </div>

                         <hr/>
                         <div className="col-12 d-flex justify-content-start">
                              <button className="btn btn-warning ml-2"
                                   onClick={e => clear(e)}>
                                   Cancelar
                              </button>
                              <button className="btn btn-primary ml-2 salvar"
                                   onClick={e => save(e)}>
                                   Salvar
                              </button>
                         </div>                        
                              
                    </div>
                        
               </div>
          );
     }
          return (
               <Main {...headerProps} >
                    {CarregaAbas()}
               </Main>
          );
          }