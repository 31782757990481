import React, {useEffect, useState} from 'react';
import Main from '../../components/template/Main';

import './Auth.css'
import logo from '../../assets/imgs/logo.png'
import logo_granja from '../../assets/imgs/logo_granja_login.png'
import ToastAnimated from '../../components/Toast/Toast'
import {post} from '../../store/config/register';
import {setLocalStorage,getLocalStorage} from '../../store/config/storage';
import {useHistory} from 'react-router-dom';
import { authKey, secretKey} from "../../global"
import CryptoJS from "crypto-js";
import { format } from 'date-fns';
import { useAddToHomescreenPrompt } from "../Install/useAddToHomescreenPrompt.tsx";
import { useParams,Link } from 'react-router-dom';
import {VscEye,VscEyeClosed} from 'react-icons/vsc'
import ModalInstallIOS from '../Modal/ModalInstallIOS';
import { Buffer } from 'buffer';
import { GravarSessao } from '../../util/Sessao/GravarSessao/index.js';
import { buscaItemSessao } from '../../util/Sessao/BuscarItemSessao/index.js';
import { Descriptografar } from '../../util/Decriptar/index.js';



import usePWAInstall from 'use-pwa-install';



var initialState = {  showSignup: false,
    login: {
        email: '',
        senha:'',
        id_sistema: 1
    },
toast: {
    tipo: '',
    message: ''
} };

const headerProps = {
    icon: "key",
    title: "Login",
    subtitle: "Login do Sistema"
}

export default function Auth(props){

    const history = useHistory();
    const [login,setLogin]=useState({...initialState.login});
    const [error,setError]=useState('');
    const [isVisible, setVisibleState] = useState(false);
    const [showMessageInstall,setShowMessageInstall]= useState(false); 
    const { isInstalled, install } = usePWAInstall();
    const [eyeIsClosed,setEyeState]=useState(false);
    const convert = (from, to) => str => Buffer.from(str, from).toString(to)

    const utf8ToHex = convert('utf8', 'hex')
    const hexToUtf8 = convert('hex', 'utf8')

    const search = props.location.search;

    const params = new URLSearchParams(search); 

    let empresa_id=undefined;
    if(params !== undefined){
        if(params.get("empresa")!==null){
            empresa_id = hexToUtf8( params.get("empresa"))
            GravarSessao(JSON.stringify({empresa: empresa_id}))
        }
    }
   
    const hide = () => setVisibleState(false);
    function  updateField( event){
        const logi ={...login}
        logi[event.target.name] =event.target.value
        setLogin(logi);
    }
    let empresa = buscaItemSessao().empresa === null ? undefined : JSON.parse(buscaItemSessao().empresa).empresa;
  
   

    useEffect(()=>{
        if (isIos() && !isInStandaloneMode()) {
            setShowMessageInstall(true);
          }
        if(empresa !== undefined && empresa_id == undefined  ){
           
        //    let emp =JSON.parse(empresa).empresa;
        empresa = utf8ToHex(empresa);
            history.push('/Auth?empresa='+empresa);
        }else if (empresa === undefined && empresa_id !== undefined){
          empresa = hexToUtf8(empresa_id);
          //  localStorage.setItem("empresa",JSON.stringify({empresa: empresa}));
           // setLocalStorage("empresa",JSON.stringify({empresa: empresa_Id}),11664000)
            GravarSessao(JSON.stringify({empresa: empresa_id}))
        }
       
        if(empresa === undefined){
        let resp =criptoID()   

           // setLocalStorage("empresa",JSON.stringify({empresa: resp}),11664000)
            GravarSessao(JSON.stringify({empresa: resp}))
            resp =  utf8ToHex(resp)
           history.push('/Auth?empresa='+resp);
        
        }
      
    },[])

   
 
      
// Detects if device is on iOS 
const isIos = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test( userAgent );
  }
  // Detects if device is in standalone mode
  const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);
  
  

    function criptoID(){
        return CryptoJS.AES.encrypt('1', secretKey).toString();
        // if(empresa === undefined){
        //     let emp =CryptoJS.AES.encrypt('1', secretKey).toString();
        //     id =emp;
        //     return emp;
           
        // }else{
        //     let emp =JSON.parse(empresa).empresa;
        //     id =emp;
        //     return emp;
        // }
       
    }

    useEffect(()=>{
        if (prompt) {
          setVisibleState(true);
        }

    
      },[prompt])

    
    function Validar( ){
        if(login.email === ''){
            setError('Preencha o login')
            return false;
        }

        if(login.senha === ''){
            setError('Preencha a senha')
            return false;
        }
        setError('')
        return true;
    }

    async function  signin(){
        if(Validar()) {
           
            login.empresa_Id =parseInt(Descriptografar(empresa));
            const lo=await post('/Login/v1/Validar', login);
            if(lo.id === undefined){
                setError('usuário ou senha inválido!')
            }else {

                if(lo.confirmacao === false) {
                    setLocalStorage("messagem",JSON.stringify({message: "Foi enviado um email de confirmação. Entre no email e clique no link para confirmar o seu cadastro."}),11664000)
                   history.push(`/Autorizacao/${lo.nome}`);
                    window.location.reload();
                }else {
                    if(lo.status === true){
                
                        let resposta =lo.id+"/"+lo.email+"/"+lo.empresa_Id;
                        let data ={login_id: lo.id, data: format(Date.now(),"yyyy-MM-dd'T'HH:mm:ss") , empresa_Id:lo.empresa_Id}
                        await post('/LogAcesso/v1', data);
                        
                        const auth = { ID: lo.id, nome: lo.nome, Token: CryptoJS.AES.encrypt(resposta, secretKey).toString(), Time: new Date().getTime() + (60000 * 11664000),Admin: lo.admin}
                        setLocalStorage(authKey,JSON.stringify(auth) ,11664000)
                        history.push('/');
                        window.location.reload();
                    }else{
                        setLocalStorage("messagem",JSON.stringify({message:"Aguardando autorização do Condomínio..."}),5)
                    history.push(`/Autorizacao/${lo.nome}`);
                    window.location.reload();
                      
                  }
                }

                
            }

        }
       
                
    }

    function Cadastrar() {
        history.push('/Cadastrar')
    }
    
    // function montaUser(obj){

        
    //     let tokenApi= getLocalStorage("acessToken");
     
    //     return  {
    //      id: obj.id,
    //      id_grupo: obj.id_grupo,
    //      id_sistema: obj.id_sistema,
    //      login: obj.login,
    //      nome: obj.nome,
    //      empresa_id:obj.empresa_id,
    //      token: tokenApi
    //  }
    //      }

    function changeType(){
        setEyeState(!eyeIsClosed);
    }

    function togglePostModalIOSInstall() { 
        setShowMessageInstall(!showMessageInstall)
      
      }

    function renderForm(){
        return (
        <div class="auth-content">
            <div class="auth-modal">
                {
                  empresa_id !== undefined ? 
                  Descriptografar(empresa_id) === '1' ? (
                    <img src= {logo} width="200" alt="Logo"/>
                )
                :
                (
                    <img src= {logo_granja} width="200" alt="Logo"/>
                )
            :
            (
                <img src= {logo} width="200" alt="Logo"/>
            )
            }
                
                <hr/>
                 <div class="auth-title"> { 'Login'} </div>       
              <input className='email'  value={login.email} name="email" placeholder="Email" type="text" onChange={(e) =>updateField(e)}></input>
              <div class="input-group mb-3">
                <input name='senha' value ={login.senha}
                type= {!eyeIsClosed ? "password" :"text"} 
                className="senha" placeholder="Senha" onChange={(e) =>updateField(e)} />
                <div class="input-group-append">
                 <button className='buttonVisible'  type="button" color='#fff' onClick={changeType}>{eyeIsClosed ? <VscEyeClosed color='#808080' /> :<VscEye color='#808080'/>}</button>
                 </div>
                </div>
              {showMessageInstall && (<ModalInstallIOS  close={togglePostModalIOSInstall}/>)}
            <ToastAnimated />
             <button className='btn btn-primary' onClick={async () => signin()}>Entrar</button>
             {error !== '' ? (
                <div>
                <br />
                <label style={{color: '#FF0000'}}>{error}</label>
                </div>

             ): null
             }
              <div>
                <br />
                <label style={{color:'#A0A0A0',fontSize:15}}>Esqueceu sua senha?</label>
                <Link to="/Usuario/EsqueciSenha" style={{ color: '#2A4C1D',fontWeight: 'bold',fontSize:15}}> Clique aqui</Link>
                </div>
             <hr />
             <div className='nova'>
             <button className='btn'style={{backgroundColor: '#2A4C1D'}} onClick={Cadastrar}>Criar Nova Conta</button> 
             </div>
             {!isInstalled && (
                 
        <div className='nova'>

        <button className='btn btn-dark' style={{marginTop: "10px"}} onClick={install}>Instalar</button>
        </div>
      )}
                                                      
            </div>
        </div>
        );
    }
    return (
        <Main {...headerProps}>
            {renderForm()}
        </Main>
    );
}