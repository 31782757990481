import React,{useState} from "react";
import Main from "../../components/template/Main";
import JoditEditor from "jodit-react";
import { login_Id,empresa_Id } from "../../store/config/dadosuser";
import {post} from '../../store/config/register';
import { format } from "date-fns";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

const copyStringToClipboard = function(str) {
    var el = document.createElement("textarea");
    el.value = str;
    el.setAttribute("readonly", "");
    el.style = { position: "absolute", left: "-9999px" };
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
  };
  
  const facilityMergeFields = [
    "FacilityNumber",
    "FacilityName",
    "Address",
    "MapCategory",
    "Latitude",
    "Longitude",
    "ReceivingPlant",
    "TrunkLine",
    "SiteElevation"
  ];
  const inspectionMergeFields = [
    "InspectionCompleteDate",
    "InspectionEventType"
  ];
  const createOptionGroupElement = (mergeFields, optionGrouplabel) => {
    let optionGroupElement = document.createElement("optgroup");
    optionGroupElement.setAttribute("label", optionGrouplabel);
    for (let index = 0; index < mergeFields.length; index++) {
      let optionElement = document.createElement("option");
      optionElement.setAttribute("class", "merge-field-select-option");
      optionElement.setAttribute("value", mergeFields[index]);
      optionElement.text = mergeFields[index];
      optionGroupElement.appendChild(optionElement);
    }
    return optionGroupElement;
  }
  const buttons = [
    "undo",
    "redo",
    "|",
    "bold",
    "strikethrough",
    "underline",
    "italic",
    "|",
    "align",
    "|",
    "ul",
    "ol",
    "outdent",
    "indent",
    "|",
    "font",
    "fontsize",
    "brush",
    "paragraph",
    "|",
    "image",
    {
      name: "insertMergeField",
      tooltip: "Insert Merge Field",
      iconURL: "images/merge.png",
      popup: (editor, current, self, close) => {
        function onSelected(e) {
          let mergeField = e.target.value;
          if (mergeField) {
            editor.selection.insertNode(
              editor.create.inside.fromHTML("{{" + mergeField + "}}")
            );
          }
        }
        let divElement = editor.create.div("merge-field-popup");
  
        let labelElement = document.createElement("label");
        labelElement.setAttribute("class", "merge-field-label");
        labelElement.text = 'Merge field: ';
        divElement.appendChild(labelElement);
  
        let selectElement = document.createElement("select");
        selectElement.setAttribute("class", "merge-field-select");
        selectElement.appendChild(createOptionGroupElement(facilityMergeFields, "Facility"));
        selectElement.appendChild(createOptionGroupElement(inspectionMergeFields, "Inspection"));
        selectElement.onchange = onSelected;
        divElement.appendChild(selectElement);
  
      //  console.log(divElement);
        return divElement;
      }
    },
    {
      name: "copyContent",
      tooltip: "Copy HTML to Clipboard",
      iconURL: "images/copy.png",
      exec: function(editor) {
        let html = editor.value;
        copyStringToClipboard(html);
      }
    }
  ];

  const editorConfig = {
    readonly: false,
    toolbar: true,
    spellcheck: true,
    language: "pt_br",
    toolbarButtonSize: "medium",
    toolbarAdaptive: false,
    showCharsCounter: true,
    showWordsCounter: true,
    showXPathInStatusbar: false,
    askBeforePasteHTML: true,
    askBeforePasteFromWord: true,
    //defaultActionOnPaste: "insert_clear_html",
    buttons: buttons,
    uploader: {
      insertImageAsBase64URI: true
    },
    width: 800,
    height: 600
  };

  const headerProps = {
    icon: 'envelope-o',
    title: 'Cadastro de Ocorrência'
  }


  const initialState = {
    ocorrencia: {
      id: 0,
      titulo: '',
      messagem: '',
      data: format(Date.now(),"yyyy-MM-dd'T'HH:mm:ss"),
      empresa_Id: empresa_Id,
      status: 'A',
      solucao_Id: 0,
      login_Id: login_Id
    }
  }
export default function OcorrenciaCadastro(){

    const [ocorrencia,setOcorrencia] = useState(initialState.ocorrencia)
    const [data,setData] = useState('');
    const history = useHistory();

    async function save () {
      const data = {titulo: ocorrencia.titulo, mensagem: ocorrencia.messagem,data: format(Date.now(),"yyyy-MM-dd'T'HH:mm:ss") ,empresa_Id,status: 'A',login_Id}
      await post('/Ocorrencia/v1',data);
      await toast.success('Ocorrência salvo com sucesso');

      history.push('/Ocorrencia')
    }

    function updateField(e){
      let oco = {...ocorrencia};
      oco[e.target.name] =e.target.value;
      setOcorrencia({...oco})
  }

    function renderForm(){
        return (
            <div>
              
                <div className="editorCadastro">
                <div className="field">
                  <label><i><strong>Assunto: </strong></i></label>
                  <input type="text" value={ocorrencia.titulo} name="titulo" className="form-control assunto"
                  onChange={(e) =>{updateField(e)}} />
                </div>
                <label><strong>Comentários</strong></label>
                  <JoditEditor
                    value={data}
                    config={editorConfig}
                    onChange={value => {
                      setData(value); 
                      setOcorrencia({...ocorrencia,messagem: value});
                      ;}}

                  />
                </div>
                <hr />

                <div className="btnSalvar">
                  <button className="btn btn-primary" onClick={()=> save()}>Salvar</button>
                  <button className="btn btn-warning ml-2" onClick={()=> history.push('/Ocorrencia')}>Cancelar</button>
                 
                </div>
            </div>
        );
    }

    return (
        <Main {...headerProps}>
            {renderForm()}
        </Main>
    );
}