import React,{useState,useEffect,useRef} from "react";
import Main from "../../components/template/Main";
import { parse,format } from "date-fns";
import moment from "moment";
import useTable from "../../components/table/useTable";
import TableFooter from "../../components/table/TableFooter";
import { toBase64,replaceType } from "../../util/Imagem";
import { empresa_Id } from "../../store/config/dadosuser";
import { get,put } from "../../store/config/register";
import { Search } from 'react-bootstrap-icons';
import {FiFile} from 'react-icons/fi';
import { toast } from 'react-toastify';
import ModalImagem from '../Modal/ModalImagem';
import './ReservasCanceladaMulta.css';
import { AbrirPDFEmNovaAba } from "../../util/File";
import Compressor from 'compressorjs';
import  DatePicker  from "react-mobile-datepicker";
import { Tabs,Tab } from "react-bootstrap";


const headerProps = {
    icon: 'calendar',
    title: 'Status Agendamentos'
  }

  const initialState ={
    agendamento: {
        dia: 5,
        espaco_id: 0,
        data: new Date(),
        data_mes: new Date(),
    }
  }

   const dateConfig = {
    'month': {
        format: 'MM',
        caption: 'Mês',
        step: 1,
    },
    'year': {
      format: 'YYYY',
      caption: 'Ano',
      step: 1,
  }
    
  };

export default function ReservasCanceladasMulta(){

    const [agendamento,setAgendamento] = useState(initialState.agendamento);
    const [selectEspacos, setSelectEspacos] =useState([]);
    const [espacos,setEspacos] =useState([]);
    const [pagamento,setPagamento]= useState("");
    const [agendaSelecionada,setAgendaSelecionada]=useState({})
    const [showImagemModal, setShowImagemModal] = useState(false);
    const [agendamentos,setAgendamentos] =useState([]);
    const [agendamentosCanceladosSemMulta,setAgendamentosCanceladosSemMulta] =useState([]);
    const [agendamentosAtivos,setAgendamentosAtivos] =useState([]);
    const [agendamentosPagosAtivo,setAgendamentosPagosAtivo] =useState([]);
    const [agendamentosPagosInativo,setAgendamentosPagosInativo] =useState([]);
    const [agendamentosNaoPagosAtivos,setAgendamentosNaoPagosAtivos] =useState([]);
    const [agendamentosNaoPagosInativos,setAgendamentosNaoPagosInativos] =useState([]);
    const [isOpen,setIsOpen]= useState(false);
    const hiddenFileInput = useRef(null);
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());


    function getWindowDimensions() {
      const { innerWidth: width, innerHeight: height } = window;
      return {
        width,
        height
      };
    }
    

    
      useEffect(() => {
        function handleResize() {
          setWindowDimensions(getWindowDimensions());
        }
    
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
      }, []);

    

    function toggleImagemModal(item,tipo) {
        type = tipo;
    if(type.includes('pdf') === false){
      setShowImagemModal(!showImagemModal);
    }else {
      AbrirPDFEmNovaAba(item)
    }
      let data = item
      setPagamento(data)
    }

    function anexarComprovante(agenda){
        setAgendaSelecionada(agenda);
        hiddenFileInput.current.click(agenda);
      }
  

    function renderSelectEspaco(){    
        if(espacos !== undefined){
            return espacos.map(espaco =>{
                return (<option key={espaco.id} value={espaco.id}>{espaco.nome}</option>)
            })
        }        
    }

    let type='';
    
      async function handleFile(e){
    
        if(e.target.files[0]) {
            const image = e.target.files[0];
    
              if(image.type === 'image/jpeg' || image.type === 'image/png' || image.type === 'application/pdf'){
    
                     if(image.type === 'image/jpeg' || image.type === 'image/png'){
                      new Compressor( image, {
                        quality: 0.6, // 0.6 can also be used, but its not recommended to go below.
                        success: async( compressedResult) => {
                          // compressedResult has the compressed file.
                          // Use the compressed file to upload the images to your server.        
                          type = compressedResult.type;
                          let base64 = await toBase64(compressedResult);    
                          let resp =  replaceType(base64);
                          agendaSelecionada.tipo = compressedResult.type;
                         await AtualizaAgenda(resp,agendaSelecionada);
                      },
                      });
                     }else{
                      type =image.type;
                      let base64 = await toBase64(image);  
                      let resp =  replaceType(base64);
                      agendaSelecionada.tipo = image.type;
                     await AtualizaAgenda(resp,agendaSelecionada);
                     }
              
        }
                }else{
                    alert('Envie uma imagem do tipo PNG ou JPEG');
                }
    }

    useEffect(()=> {
        BuscaEspacos();

    },[]);

    async function BuscaEspacos(){
        const esps= await get(`/Espaco/v1/Empresa/${empresa_Id}`)
        if(esps.length> 0 ){
   
            setAgendamento({...agendamento,espaco_id: esps[0].id})
               setEspacos(esps)
           }
    }

    function trocaEspaco(e) {
        setAgendamento({...agendamento, espaco_id: parseInt(e.target.value)})
      }

      function handleCancel() {
        setIsOpen(false);
      }
      
      function handleSelect (time) {
        setIsOpen(false);
        setAgendamento({...agendamento, data:time})
      }

      async function AtualizaAgenda(pagamento, agendaEscolhida) {
          let agend =await get('/EspacoAgenda/v1/'+ agendaEscolhida.id)
          agend.pagamento =pagamento;
          agend.tipo =agendaEscolhida.tipo;
      
          await put('/EspacoAgenda/v1/'+ agendaEscolhida.id,agend)
          await toast.success('Pagamento anexado com sucesso');
          executarBusca();
        }


      

      function renderTableNaoPagosInativos(){
        return (
            <div  className="tabela">
            <table>
                <thead>
                {windowDimensions.width > 576 ? (
                          <tr>
                        <th scope="col">Unidade</th>
                        <th scope="col">Data</th>
                        <th scope="col">Celular</th>
                        </tr>
                        ):
                        (
                        <tr>
                        <th scope="col">Unidade</th>
                        <th scope="col">Data</th>
                        <th scope="col">Celular</th>
                        <th scope="col">Visualizar</th>
                        <th scope="col">Anexar Comprovante</th>
                        </tr>
                        )
                      }
                </thead>
                {agendamentosNaoPagosInativos.length === 0 ? null : agendamentosNaoPagosInativos.map((item,index) => {
                                return( 
                                    <tbody>
                                        <tr key={index}>
                                            <td  data-label="Unidade">{item.unidade}</td>
                                            <td  data-label="Data">{format(parse(item.data,"yyyy-MM-dd'T'HH:mm:ss",new Date()),'dd/MM/yyyy HH:mm')}</td>
                                            <td  data-label="Celular">{item.celular_Responsavel}</td>
                                            {windowDimensions.width > 576 ? (
                                                <td  data-label="">
                                                <div >
                                      <input type="file"  onChange={e => handleFile(e)} ref={hiddenFileInput} style={{display: 'none'}}/>  
                                      </div>
                                     
                                     
                                        {item.pagamento !== null ? (
                                           <button className="action" id="btn1" style={{backgroundColor: '#008000' ,marginRight: "10px" }} data-toggle="tooltip" data-placement="bottom" title="Visualizar" onClick={() => toggleImagemModal(item.pagamento, item.tipo)}>
                                           <Search color="#FFF" size={17} />
                                           </button>
                                        ):(
                                          <button className="action" id="btn1" style={{backgroundColor: '#FF0000' ,marginRight: "10px" }} data-toggle="tooltip" data-placement="bottom" title="Visualizar" onClick={() => toast.warning("Comprovante de pagamento não anexado.")}>
                                           <Search color="#FFF" size={17} />
                                           </button>
                                        )}
                                      {/* <button className="action" id="btn1" style={{backgroundColor: '	#909090' ,marginRight: "10px" }} data-toggle="tooltip" data-placement="bottom" title="Editar" onClick={() => togglePostModal(agenda)}>
                                        <FiPrinter color="#FFF" size={17} />
                                        </button> */}
                                         <button className="action"  style={{backgroundColor: '#228B22' ,marginRight: "10px" }} data-toggle="tooltip" data-placement="bottom" title="Anexar Comprovante" onClick={() => anexarComprovante(item)}>
                                        <FiFile color="#FFF" size={17} />
                                        </button>
                                        </td>
                                            )
                                            :
                                            (
                                              <div>
                                              <td  data-label="Visualizar">
                                            <div >
                                  <input type="file"  onChange={e => handleFile(e)} ref={hiddenFileInput} style={{display: 'none'}}/>  
                                  </div>
                                 
                                 
                                    {item.pagamento !== null ? (
                                       <button className="action" id="btn1" style={{backgroundColor: '#008000' ,marginRight: "10px" }} data-toggle="tooltip" data-placement="bottom" title="Visualizar" onClick={() => toggleImagemModal(item.pagamento, item.tipo)}>
                                       <Search color="#FFF" size={17} />
                                       </button>
                                    ):(
                                      <button className="action" id="btn1" style={{backgroundColor: '#FF0000' ,marginRight: "10px" }} data-toggle="tooltip" data-placement="bottom" title="Visualizar" onClick={() => toast.warning("Comprovante de pagamento não anexado.")}>
                                       <Search color="#FFF" size={17} />
                                       </button>
                                    )}
                                    </td>
      
                                      <td  data-label="Anexar Comprovante">
                                      <button className="action"  style={{backgroundColor: '#228B22' ,marginRight: "10px" }} data-toggle="tooltip" data-placement="bottom" title="Anexar Comprovante" onClick={() => anexarComprovante(item)}>
                                      <FiFile color="#FFF" size={17} />
                                      </button>
                                      </td>
                                      </div>
                                  )}
                                        </tr>
                                    </tbody>
                                );
                                })}
            </table>
             </div>
        );
      }
      function renderTablePagosInativos(){
        return (
            <div  className="tabela">
            <table>
                <thead>
                {windowDimensions.width > 576 ? (
                        <tr>
                        <th scope="col">Unidade</th>
                        <th scope="col">Data</th>
                        <th scope="col">Celular</th>
                        </tr>
                        ):
                        (
                        <tr>
                        <th scope="col">Unidade</th>
                        <th scope="col">Data</th>
                        <th scope="col">Celular</th>
                        <th scope="col">Visualizar</th>
                        <th scope="col">Anexar Comprovante</th>
                        </tr>
                        )
                      }
                </thead>
                {agendamentosPagosInativo.length === 0 ? null : agendamentosPagosInativo.map((item,index) => {
                                return( 
                                    <tbody>
                                        <tr key={index}>
                                            <td  data-label="Unidade">{item.unidade}</td>
                                            <td  data-label="Data">{format(parse(item.data,"yyyy-MM-dd'T'HH:mm:ss",new Date()),'dd/MM/yyyy HH:mm')}</td>
                                            <td  data-label="Celular">{item.celular_Responsavel}</td>
                                            {windowDimensions > 576 ? (
                                                <td  data-label="">
                                                <div >
                                      <input type="file"  onChange={e => handleFile(e)} ref={hiddenFileInput} style={{display: 'none'}}/>  
                                      </div>
                                     
                                     
                                        {item.pagamento !== null ? (
                                           <button className="action" id="btn1" style={{backgroundColor: '#008000' ,marginRight: "10px" }} data-toggle="tooltip" data-placement="bottom" title="Visualizar" onClick={() => toggleImagemModal(item.pagamento, item.tipo)}>
                                           <Search color="#FFF" size={17} />
                                           </button>
                                        ):(
                                          <button className="action" id="btn1" style={{backgroundColor: '#FF0000' ,marginRight: "10px" }} data-toggle="tooltip" data-placement="bottom" title="Visualizar" onClick={() => toast.warning("Comprovante de pagamento não anexado.")}>
                                           <Search color="#FFF" size={17} />
                                           </button>
                                        )}
                                      {/* <button className="action" id="btn1" style={{backgroundColor: '	#909090' ,marginRight: "10px" }} data-toggle="tooltip" data-placement="bottom" title="Editar" onClick={() => togglePostModal(agenda)}>
                                        <FiPrinter color="#FFF" size={17} />
                                        </button> */}
                                         <button className="action"  style={{backgroundColor: '#228B22' ,marginRight: "10px" }} data-toggle="tooltip" data-placement="bottom" title="Anexar Comprovante" onClick={() => anexarComprovante(item)}>
                                        <FiFile color="#FFF" size={17} />
                                        </button>
                                        </td>
                                            )
                                            :
                                            (
                                              <div>
                                              <td  data-label="Visualizar">
                                            <div >
                                  <input type="file"  onChange={e => handleFile(e)} ref={hiddenFileInput} style={{display: 'none'}}/>  
                                  </div>
                                 
                                 
                                    {item.pagamento !== null ? (
                                       <button className="action" id="btn1" style={{backgroundColor: '#008000' ,marginRight: "10px" }} data-toggle="tooltip" data-placement="bottom" title="Visualizar" onClick={() => toggleImagemModal(item.pagamento, item.tipo)}>
                                       <Search color="#FFF" size={17} />
                                       </button>
                                    ):(
                                      <button className="action" id="btn1" style={{backgroundColor: '#FF0000' ,marginRight: "10px" }} data-toggle="tooltip" data-placement="bottom" title="Visualizar" onClick={() => toast.warning("Comprovante de pagamento não anexado.")}>
                                       <Search color="#FFF" size={17} />
                                       </button>
                                    )}
                                    </td>
      
                                      <td  data-label="Anexar Comprovante">
                                      <button className="action"  style={{backgroundColor: '#228B22' ,marginRight: "10px" }} data-toggle="tooltip" data-placement="bottom" title="Anexar Comprovante" onClick={() => anexarComprovante(item)}>
                                      <FiFile color="#FFF" size={17} />
                                      </button>
                                      </td>
                                      </div>
                                  )}
                                        </tr>
                                    </tbody>
                                );
                                })}
            </table>
             </div>
        );
      }

      function renderTableAtivosPagos(){
        return (
            <div className="tabela">
            <table>
                <thead>
                {windowDimensions.width > 576 ? (
                          <tr>
                        <th scope="col">Unidade</th>
                        <th scope="col">Data</th>
                        <th scope="col">Celular</th>
                        </tr>
                        ):
                        (
                        <tr>
                        <th scope="col">Unidade</th>
                        <th scope="col">Data</th>
                        <th scope="col">Celular</th>
                        <th scope="col">Visualizar</th>
                        <th scope="col">Anexar Comprovante</th>
                        </tr>
                        )
                      }
                </thead>
                {  agendamentosPagosAtivo.length === 0 ? null : agendamentosPagosAtivo.map((item,index) => {
                                return( 
                                    <tbody>
                                        <tr key={index}>
                                            <td data-label="Unidade">{item.unidade}</td>
                                            <td  data-label="Data">{format(parse(item.data,"yyyy-MM-dd'T'HH:mm:ss",new Date()),'dd/MM/yyyy HH:mm')}</td>
                                            <td  data-label="Celular">{item.celular_Responsavel}</td>
                                            {windowDimensions.width > 576 ? (
                                                <td  data-label="">
                                                <div >
                                      <input type="file"  onChange={e => handleFile(e)} ref={hiddenFileInput} style={{display: 'none'}}/>  
                                      </div>
                                     
                                     
                                        {item.pagamento !== null ? (
                                           <button className="action" id="btn1" style={{backgroundColor: '#008000' ,marginRight: "10px" }} data-toggle="tooltip" data-placement="bottom" title="Visualizar" onClick={() => toggleImagemModal(item.pagamento, item.tipo)}>
                                           <Search color="#FFF" size={17} />
                                           </button>
                                        ):(
                                          <button className="action" id="btn1" style={{backgroundColor: '#FF0000' ,marginRight: "10px" }} data-toggle="tooltip" data-placement="bottom" title="Visualizar" onClick={() => toast.warning("Comprovante de pagamento não anexado.")}>
                                           <Search color="#FFF" size={17} />
                                           </button>
                                        )}
                                      {/* <button className="action" id="btn1" style={{backgroundColor: '	#909090' ,marginRight: "10px" }} data-toggle="tooltip" data-placement="bottom" title="Editar" onClick={() => togglePostModal(agenda)}>
                                        <FiPrinter color="#FFF" size={17} />
                                        </button> */}
                                         <button className="action"  style={{backgroundColor: '#228B22' ,marginRight: "10px" }} data-toggle="tooltip" data-placement="bottom" title="Anexar Comprovante" onClick={() => anexarComprovante(item)}>
                                        <FiFile color="#FFF" size={17} />
                                        </button>
                                        </td>
                                            )
                                            :
                                            (
                                              <div>
                                              <td  data-label="Visualizar">
                                            <div >
                                  <input type="file"  onChange={e => handleFile(e)} ref={hiddenFileInput} style={{display: 'none'}}/>  
                                  </div>
                                 
                                 
                                    {item.pagamento !== null ? (
                                       <button className="action" id="btn1" style={{backgroundColor: '#008000' ,marginRight: "10px" }} data-toggle="tooltip" data-placement="bottom" title="Visualizar" onClick={() => toggleImagemModal(item.pagamento, item.tipo)}>
                                       <Search color="#FFF" size={17} />
                                       </button>
                                    ):(
                                      <button className="action" id="btn1" style={{backgroundColor: '#FF0000' ,marginRight: "10px" }} data-toggle="tooltip" data-placement="bottom" title="Visualizar" onClick={() => toast.warning("Comprovante de pagamento não anexado.")}>
                                       <Search color="#FFF" size={17} />
                                       </button>
                                    )}
                                    </td>
      
                                      <td  data-label="Anexar Comprovante">
                                      <button className="action"  style={{backgroundColor: '#228B22' ,marginRight: "10px" }} data-toggle="tooltip" data-placement="bottom" title="Anexar Comprovante" onClick={() => anexarComprovante(item)}>
                                      <FiFile color="#FFF" size={17} />
                                      </button>
                                      </td>
                                      </div>
                                  )}
                                        </tr>
                                    </tbody>
                                );
                                })
                }
            </table>
            </div>
        );
      }

      function renderTableAtivosNaoPagos(){
        return (
            <div className="tabela">
            <table>
                <thead>
                    
                        {windowDimensions.width > 576 ? (
                          <tr>
                        <th scope="col">Unidade</th>
                        <th scope="col">Data</th>
                        <th scope="col">Celular</th>
                        </tr>
                        ):
                        (
                        <tr>
                        <th scope="col">Unidade</th>
                        <th scope="col">Data</th>
                        <th scope="col">Celular</th>
                        <th scope="col">Visualizar</th>
                        <th scope="col">Anexar Comprovante</th>
                        </tr>
                        )
                      }
                   
                </thead>
                {  agendamentosNaoPagosAtivos.length === 0 ? null : agendamentosNaoPagosAtivos.map((item,index) => {
                                return( 
                                    <tbody>
                                        <tr key={index}>
                                            <td data-label="Unidade">{item.unidade}</td>
                                            <td  data-label="Data">{format(parse(item.data,"yyyy-MM-dd'T'HH:mm:ss",new Date()),'dd/MM/yyyy HH:mm')}</td>
                                            <td  data-label="Celular">{item.celular_Responsavel}</td>
                                            {windowDimensions.width > 576 ? (
                                                <td  data-label="">
                                                <div >
                                      <input type="file"  onChange={e => handleFile(e)} ref={hiddenFileInput} style={{display: 'none'}}/>  
                                      </div>
                                     
                                     
                                        {item.pagamento !== null ? (
                                           <button className="action" id="btn1" style={{backgroundColor: '#008000' ,marginRight: "10px" }} data-toggle="tooltip" data-placement="bottom" title="Visualizar" onClick={() => toggleImagemModal(item.pagamento, item.tipo)}>
                                           <Search color="#FFF" size={17} />
                                           </button>
                                        ):(
                                          <button className="action" id="btn1" style={{backgroundColor: '#FF0000' ,marginRight: "10px" }} data-toggle="tooltip" data-placement="bottom" title="Visualizar" onClick={() => toast.warning("Comprovante de pagamento não anexado.")}>
                                           <Search color="#FFF" size={17} />
                                           </button>
                                        )}
                                      {/* <button className="action" id="btn1" style={{backgroundColor: '	#909090' ,marginRight: "10px" }} data-toggle="tooltip" data-placement="bottom" title="Editar" onClick={() => togglePostModal(agenda)}>
                                        <FiPrinter color="#FFF" size={17} />
                                        </button> */}
                                         <button className="action"  style={{backgroundColor: '#228B22' ,marginRight: "10px" }} data-toggle="tooltip" data-placement="bottom" title="Anexar Comprovante" onClick={() => anexarComprovante(item)}>
                                        <FiFile color="#FFF" size={17} />
                                        </button>
                                        </td>
                                            )
                                            :
                                            (
                                              <div>
                                              <td  data-label="Visualizar">
                                            <div >
                                  <input type="file"  onChange={e => handleFile(e)} ref={hiddenFileInput} style={{display: 'none'}}/>  
                                  </div>
                                 
                                 
                                    {item.pagamento !== null ? (
                                       <button className="action" id="btn1" style={{backgroundColor: '#008000' ,marginRight: "10px" }} data-toggle="tooltip" data-placement="bottom" title="Visualizar" onClick={() => toggleImagemModal(item.pagamento, item.tipo)}>
                                       <Search color="#FFF" size={17} />
                                       </button>
                                    ):(
                                      <button className="action" id="btn1" style={{backgroundColor: '#FF0000' ,marginRight: "10px" }} data-toggle="tooltip" data-placement="bottom" title="Visualizar" onClick={() => toast.warning("Comprovante de pagamento não anexado.")}>
                                       <Search color="#FFF" size={17} />
                                       </button>
                                    )}
                                    </td>
      
                                      <td  data-label="Anexar Comprovante">
                                      <button className="action"  style={{backgroundColor: '#228B22' ,marginRight: "10px" }} data-toggle="tooltip" data-placement="bottom" title="Anexar Comprovante" onClick={() => anexarComprovante(item)}>
                                      <FiFile color="#FFF" size={17} />
                                      </button>
                                      </td>
                                      </div>
                                  )}
                                            
                                  </tr>
                                    </tbody>
                                );
                                })
                }
            </table>
            </div>
        );
      }

      function TabAtivos(){
        return (
            <div>
                  <label>Ativos</label>
                  <hr/>
                  <Tabs defaultActiveKey={0}>
                    <Tab title="Pagos" eventKey={0}>
                        {renderTableAtivosPagos()}
                    </Tab>
                    <Tab title="Não Pagos" eventKey={1}>
                        {renderTableAtivosNaoPagos()}
                    </Tab>
                  </Tabs>
            </div>
        );
      }

      function TabInativos(){
        return (
            <div>
                  <label>Inativos</label>
                  <hr/>
                  <Tabs defaultActiveKey={0}>
                    <Tab title="Pagos" eventKey={0}>
                        {renderTablePagosInativos()}
                    </Tab>
                    <Tab title="Não Pagos" eventKey={1}>
                        {renderTableNaoPagosInativos()}
                    </Tab>
                  </Tabs>
            </div>
        );
      }

      function TabCancelados(){
        return (
            <div>
                  <label>Cancelados</label>
                  <hr/>
                  <Tabs defaultActiveKey={0}>
                    <Tab title="Com Multa" eventKey={0}>
                        {renderTableCancelados()}
                    </Tab>
                    <Tab title="Sem Multa" eventKey={1}>
                        {renderTableCanceladosSemMulta()}
                    </Tab>
                  </Tabs>
            </div>
        );
      }

      function renderTableCancelados(){
        return (
            <div className="tabela">
            <table >
                <thead>
                    <tr>
                        <th scope="col">Unidade</th>
                        <th scope="col">Data</th>
                        <th scope="col">Celular</th>
                        <th scope="col">Cancelamento</th>
                    </tr>
                </thead>
                {  agendamentos.length === 0 ? null : agendamentos.map((item,index) => {
                let data =  parse(item.data,"yyyy-MM-dd'T'HH:mm:ss",new Date());
                let data_exclusao = parse(item.data_Exclusao,"yyyy-MM-dd'T'HH:mm:ss",new Date());
                let data_moment =moment(data);
                let data_exclusao_moment=moment(data_exclusao)

                let result=data_moment.diff(data_exclusao_moment,'hours')
                 let espaco = espacos.filter(f => f.id === agendamento.espaco_id);
       
                                return( 
                                    <tbody>
                                       
                                          {result > espaco[0].desistencia ? (
                                            <tr key={index}>
                                            <td  data-label="Unidade">{item.unidade}</td>
                                            <td  data-label="Data">{format(parse(item.data,"yyyy-MM-dd'T'HH:mm:ss",new Date()),'dd/MM/yyyy HH:mm')}</td>
                                            <td  data-label="Celular">{item.celular_Responsavel}</td>
                                            <td  data-label="Cancelamento">{format(parse(item.data_Exclusao,"yyyy-MM-dd'T'HH:mm:ss",new Date()),'dd/MM/yyyy HH:mm')}</td>
                                            </tr>
                                          ):(
                                            <tr key={index}>
                                            <td  data-label="Unidade" style={{color:  "#FF0000"}}>{item.unidade}</td>
                                            <td  data-label="Data" style={{color:  "#FF0000"}}>{format(parse(item.data,"yyyy-MM-dd'T'HH:mm:ss",new Date()),'dd/MM/yyyy HH:mm')}</td>
                                            <td  data-label="Celular">{item.celular_Responsavel}</td>
                                            <td  data-label="Cancelamento" style={{color:  "#FF0000"}}>{format(parse(item.data_Exclusao,"yyyy-MM-dd'T'HH:mm:ss",new Date()),'dd/MM/yyyy HH:mm')}</td>
                                            </tr>
                                          )}
                                            
                                       
                                    </tbody>
                                );
                                })}
            </table>
             </div>
        );
      }

      function renderTableCanceladosSemMulta(){
        return (
            <div className="tabela">
            <table >
                <thead>
                    <tr>
                        <th scope="col">Unidade</th>
                        <th scope="col">Data</th>
                        <th scope="col">Celular</th>
                        <th scope="col">Cancelamento</th>
                    </tr>
                </thead>
                {  agendamentosCanceladosSemMulta.length === 0 ? null : agendamentosCanceladosSemMulta.map((item,index) => {
                let data =  parse(item.data,"yyyy-MM-dd'T'HH:mm:ss",new Date());
                let data_exclusao = parse(item.data_Exclusao,"yyyy-MM-dd'T'HH:mm:ss",new Date());
                let data_moment = moment(data);
                let data_exclusao_moment=moment(data_exclusao)

                let result=data_moment.diff(data_exclusao_moment,'hours')
                 let espaco = espacos.filter(f => f.id === agendamento.espaco_id);
       
                                return( 
                                    <tbody>
                                       
                                          {result > espaco[0].desistencia ? (
                                            <tr key={index}>
                                            <td  data-label="Unidade">{item.unidade}</td>
                                            <td  data-label="Data">{format(parse(item.data,"yyyy-MM-dd'T'HH:mm:ss",new Date()),'dd/MM/yyyy HH:mm')}</td>
                                            <td  data-label="Celular">{item.celular_Responsavel}</td>
                                            <td  data-label="Cancelamento">{format(parse(item.data_Exclusao,"yyyy-MM-dd'T'HH:mm:ss",new Date()),'dd/MM/yyyy HH:mm')}</td>
                                            </tr>
                                          ):(
                                            <tr key={index}>
                                            <td  data-label="Unidade" style={{color:  "#FF0000"}}>{item.unidade}</td>
                                            <td  data-label="Data" style={{color:  "#FF0000"}}>{format(parse(item.data,"yyyy-MM-dd'T'HH:mm:ss",new Date()),'dd/MM/yyyy HH:mm')}</td>
                                            <td  data-label="Celular">{item.celular_Responsavel}</td>
                                            <td  data-label="Cancelamento" style={{color:  "#FF0000"}}>{format(parse(item.data_Exclusao,"yyyy-MM-dd'T'HH:mm:ss",new Date()),'dd/MM/yyyy HH:mm')}</td>
                                            </tr>
                                          )}
                                            
                                       
                                    </tbody>
                                );
                                })}
            </table>
             </div>
        );
      }

      async  function BuscaAgendamentosCancelados() {
 
        const agend=  await get(`/EspacoAgenda/v1/Cancelado?espaco_id=${agendamento.espaco_id}&dia=${agendamento.dia}&data=${format(agendamento.data,'yyyy-MM-dd')}&ativo=false`);
          setAgendamentos(agend);

      }

      async  function BuscaAgendamentosCanceladosSemMulta() {
 
        const agend=  await get(`/EspacoAgenda/v1/Cancelado?espaco_id=${agendamento.espaco_id}&dia=${agendamento.dia}&data=${format(agendamento.data,'yyyy-MM-dd')}&ativo=true`);
          setAgendamentosCanceladosSemMulta(agend);

      }


      async  function BuscaAgendamentosAtivos(){
 
        const agend=  await get(`/EspacoAgenda/v1/Ativos?espaco_id=${agendamento.espaco_id}&data=${format(agendamento.data,'yyyy-MM-dd')}`);

  
          setAgendamentosAtivos(agend);
  
      }

      async  function BuscaAgendamentosPagoAtivo(){
 
        const agend=  await get(`/EspacoAgenda/v1/Pago?espaco_id=${agendamento.espaco_id}&data=${format(agendamento.data,'yyyy-MM-dd')}&ativo=true`);
 
          setAgendamentosPagosAtivo(agend);
     
      }

      async  function BuscaAgendamentosPagoInativo(){
 
        const agend=  await get(`/EspacoAgenda/v1/Pago?espaco_id=${agendamento.espaco_id}&data=${format(agendamento.data,'yyyy-MM-dd')}&ativo=false`);
 
          setAgendamentosPagosInativo(agend);
     
      }

      async  function BuscaAgendamentosNaoPagoAtivo(){
 
        const agend=  await get(`/EspacoAgenda/v1/NaoPago?espaco_id=${agendamento.espaco_id}&data=${format(agendamento.data,'yyyy-MM-dd')}&ativo=true`);

          setAgendamentosNaoPagosAtivos(agend);

      }

      async  function BuscaAgendamentosNaoPagoInativo(){
 
        const agend=  await get(`/EspacoAgenda/v1/NaoPago?espaco_id=${agendamento.espaco_id}&data=${format(agendamento.data,'yyyy-MM-dd')}&ativo=false`);

          setAgendamentosNaoPagosInativos(agend);

      }

      function  updateField(event) {
        const agen ={...agendamento}
          
        agen[event.target.name] =event.target.value
        setAgendamento(agen);
    }

    async function executarBusca() {
        await setAgendamento({...agendamento, data_mes: agendamento.data})
        await BuscaAgendamentosCancelados();
        await BuscaAgendamentosAtivos();
        await BuscaAgendamentosPagoAtivo();
        await BuscaAgendamentosPagoInativo();
        await BuscaAgendamentosNaoPagoAtivo();
        await BuscaAgendamentosNaoPagoInativo();
        await BuscaAgendamentosCanceladosSemMulta();
    }

    function renderForm(){
        return(
            <div className="form">
                 <div className="nome-visitante">
                <div className="form-group">
                  <label>Espaço:</label>
                  <select type="text" name="espaco" 
                    className="form-control" value={agendamento.espaco_id}  
                    onChange={(e) => trocaEspaco(e)} >{renderSelectEspaco()} </select>
                </div>
            </div> 
            <div className="data0">
              <div className="form-group">
                    <label>Mês/Ano:</label>
                    <input  name="data_Nascimento"  className="form-control" readOnly onClick={()=> {setIsOpen(true);}}
                                       value={agendamento.data !== null ?format(agendamento.data,'MM/yyyy') : ''}/> 
                                           <DatePicker confirmText="Selecionar" cancelText="Cancelar" headerFormat="MM/YYYY"className="form-control"  showCaption={true} dateConfig={dateConfig} 
                                           value={agendamento.data === null ? new Date() : agendamento.data}
                                           isOpen={isOpen}
                                           onSelect={(time)=> handleSelect(time)}
                                           onCancel={()=> handleCancel()}/>
                        
              </div>
            </div>

                    <div className="row1">
                        <button className="btn btn-primary" onClick={async e => { await executarBusca()}}>
                            Pesquisar
                        </button>
                  </div>

                  <div className="totalizador">
                                            
                    <label className="titulo">Mês: {format(agendamento.data_mes,'MM/yyyy')}</label>
                  </div>
                  <div className="totalizador">

                    <label className="titulo"><strong>Total Ativos: </strong> {agendamentosAtivos.length}</label>
                      <label className="titulo"><strong>Total Cancelados: </strong> {agendamentos.length + agendamentosCanceladosSemMulta.length}</label>
                    <label className="titulo"><strong>Total Pagos: </strong> {agendamentosPagosAtivo.length + agendamentosPagosInativo.length}</label>
                      <label className="titulo"><strong>Total Não Pagos:</strong> {agendamentosNaoPagosAtivos.length +agendamentosNaoPagosInativos.length} </label>
                  </div>

                   {showImagemModal  &&(
                    <ModalImagem
                      conteudo = {{pagamento, type}} 
                      close={toggleImagemModal}
                    />)}

                  {TabAtivos()}
                  {TabInativos()}
                  {TabCancelados()}
            </div>
        );
    }

    return (
        <Main {...headerProps}>
            {renderForm()}
        </Main>
    );
}