import React,{useEffect, useState} from "react";

import Main from "../../components/template/Main";
import { useHistory } from "react-router-dom";
import { setLocalStorage } from "../../store/config/storage";
import validator from 'validator';
import { post } from "../../store/config/register";

import {VscEye,VscEyeClosed} from 'react-icons/vsc'
import './CadastrarUser.css'
import { empresa } from "../../store/config/dadosuser"; 

const initialState ={
    usuario: {
      Id: 0,
      Empresa_Id: 0,
      Nome:'',
      Senha: '',
      Email:'',
      ConfirmarSenha: ''
    }
  }

  const headerProps = {
    icon: "edit",
    title: "Cadastro",
    subtitle: "Cadastro de usuário"
}
export default function CadastrarUser(){

    const [usuario,setUsuario] = useState(initialState.usuario);
    const [error,setError] = useState('');
    const history = useHistory(); 
    const [eyeIsClosedSenha,setEyeStateSenha]=useState(false);
    const [eyeIsClosedConfirmar,setEyeStateConfirmar]=useState(false);

    useEffect(()=>{
        if(empresa === undefined){
            window.location.reload();
        }
    },[])

    function  updateField(event) {
        const usu ={...usuario}
          
        usu[event.target.name] =event.target.value
        setUsuario(usu);
    }

    localStorage.removeItem('update');
    
    async function Cadastrar() {
        
        if(await Validar()) {
         setLocalStorage('user',usuario);
         history.push('/Condominio')
        }
        
    }

   async function Validar() {
        if(usuario.Nome === ''){
            setError('Preencha o nome');
            return false;
        }

        if(usuario.Email === '') {
            setError('Preencha o email');
            return false;
        }else {
            if(validator.isEmail(usuario.Email) === false) {
                setError('E-mail inválido.')
                return false;
            }else {
                const data ={email: usuario.Email, empresa_Id: parseInt(empresa) };

               const email = await post('/Login/v1/Validar',data)
               if(email !== ''){
                setError('E-mail já cadastrado!');
                return false;
               }
            }
        }

        if(usuario.ConfirmarSenha !== usuario.Senha) {
            setError('A confirmação da senha deve ser igual a senha.');
            return false;
        } else {
            if(usuario.Senha.length < 6) {
                setError('a senha deve ter 6 caracteres');
                return false;
            } else {
                if(valida(usuario.Senha) === true) {
                    return true;
                }else {
                    setError('A senha deve conter letras, números e caracteres especiais');
                    return false;
                }
            }
        }
    }
    function changeTypeSenha(){
        setEyeStateSenha(!eyeIsClosedSenha);
    }

    function changeTypeConfirma(){
        setEyeStateConfirmar(!eyeIsClosedConfirmar);
    }
    function valida(senha) {
       // let regex = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%*()_+^&}{:;?.])(?:([0-9a-zA-Z])(?!\1)|[!@#$%;*(){}_+^&]){6,}$/;
        let regex =/(?=.*[}{,.:;()^@#!$%?&~=+\-_*\-+.])(?=.*[a-zA-Z])(?=.*[0-9]).{6,}/;
        
        if (regex.test(senha) === true) {
           return true;
        } else {
            return false;
        }
    }

    function render() {
        return (
            <div className="form">
                <div className="email">
                <div className="form-group">
                  <label>Nome:</label>
                  <input type="text" name="Nome" 
                  placeholder="Nome"
                    className="form-control" value={usuario.Nome} 
                    onChange={e => updateField(e)} />
                </div>
            </div> 
            <div className="email">
              <div className="form-group">
                  <label>E-mail:</label>
                  <input type="email" name="Email" 
                  placeholder="Email"
                  className="form-control" value={usuario.Email} 
                  onChange={e => updateField(e)} />
              </div>
            </div>
            <div className="email">
                <div className="form-group">
                  <label>Senha:</label>
                    <div class="input-group mb-3">
                    <input name="Senha"  value={usuario.Senha}  type= {!eyeIsClosedSenha ? "password" :"text"} class="form-control" placeholder="Senha" onChange={(e) =>updateField(e)} />
                    <div class="input-group-append">
                    <button className='buttonVisibleC'  type="button" color='#fff' onClick={changeTypeSenha}>{eyeIsClosedSenha ? <VscEyeClosed color='#808080' /> :<VscEye color='#808080'/>}</button>
                    </div>
                    </div>
                </div>
            </div>
            <div className="email">
                <div className="form-group">
                  <label>Confirmar Senha:</label>
                    <div class="input-group mb-3">
                    <input name="ConfirmarSenha"  value={usuario.ConfirmarSenha}  type= {!eyeIsClosedConfirmar ? "password" :"text"} class="form-control" placeholder="Confirmar Senha" onChange={(e) =>updateField(e)} />
                    <div class="input-group-append">
                    <button className='buttonVisibleC'  type="button" color='#fff' onClick={changeTypeConfirma}>{eyeIsClosedConfirmar ? <VscEyeClosed color='#808080' /> :<VscEye color='#808080'/>}</button>
                    </div>
                    </div>
                </div>
            </div>
            <label style={{color: '#FF0000'}}>{error}</label>
            <label style={{fontWeight: 'bold'}}>*A senha deve conter no minímo 6 caracteres entre letras, números e caracteres especiais</label>
            <hr/>
            <div id="botao">
                  <button className="btn btn-primary" onClick={async (e) => Cadastrar()}>
                    Cadastrar
                  </button>
                  <button className="btn btn-secondary ml-2" onClick={e => history.push('/')}>
                    Cancelar
                  </button>
            </div>
            </div>
        );
    }
    
    return (
    <Main {...headerProps}>
        {render()}
    </Main>
    );
}