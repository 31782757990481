import React,{useState} from "react";
import { FiX } from "react-icons/fi";
import { PDFDocument } from "pdf-lib";
import { dataURLtoFile } from "../../util/Imagem";



export default function ModalPDFViewer({conteudo, close}){
    const [item,setItem]=useState(conteudo);

    const raw = window.atob(item.pagamento);
          const rawLength = raw.length;
          const blobArray = new Uint8Array(new ArrayBuffer(rawLength));

          for (let i = 0; i < rawLength; i++) {
              blobArray[i] = raw.charCodeAt(i);
          }

          const blob = new Blob([blobArray], {type: 'application/pdf'});

          const url =  window.URL.createObjectURL(blob)
          window.open(url, '_blank').focus();


          async function downloadPDF(pdf) {
            const linkSource = `data:application/pdf;base64,${pdf}`;
            const downloadLink = document.createElement("a");
            const fileName = "abc.pdf";
            downloadLink.href = linkSource;
            downloadLink.download = fileName;
          await  downloadLink.click();
         
        }

            const makeDownloadToPDF = async (b64, height, width) => {

                try {
                  const binaryString = window.atob(b64);
            
                  const pdfDoc = await PDFDocument.create();

        
                  const blob = new Blob([binaryString], {type: 'application/pdf'});
                  const imageBytes = Uint8Array.from([...binaryString].map((char) => char.charCodeAt(0)));
            
                  const image = await pdfDoc.embedPdf(binaryString);
                  const page = pdfDoc.addPage([image.width, image.height]);

                  const pdfBytes = await pdfDoc.save();
            
                  const pdfBlob = new Blob([pdfBytes], { type: 'application/pdf' });
            
                  // Create a download link
                  const downloadLink = document.createElement('a');
                  downloadLink.href = URL.createObjectURL(pdfBlob);
                  downloadLink.download = 'converted.pdf';
            
                  // Simulate click to trigger the download
                  document.body.appendChild(downloadLink);
                  downloadLink.click();
            
                  // Cleanup
                  URL.revokeObjectURL(downloadLink.href);
                  document.body.removeChild(downloadLink);
                } catch (error) {
                  console.error('Error converting image to PDF:', error);
                }
            }

//  const imagem =  dataURLtoFile(`data:image/png;base64,${item}`,'teste.png');

    return (
        <div>
        <div className="modal3">
            <div className="container">
                {/* <button className="closeAgenda" onClick={ () => close(null,item.pagamento) }>
                    <FiX size={23} color="#FFF" />
                    Voltar
                </button> */}
                <button className="closeAgenda" onClick={ async () => await downloadPDF(item.pagamento) }>
                    <FiX size={23} color="#FFF" /> 
                    Download
                </button>

                <iframe src={url} style={{width:"100%" ,height:"500px"}}  />
            </div>
        </div>
    </div>
    );
}