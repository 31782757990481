import React, { useState, useEffect,useRef } from 'react';
import Main from '../../components/template/Main';
import {get,put,post} from '../../store/config/register';
import { parse,format } from "date-fns"; 
import { empresa_Id } from '../../store/config/dadosuser';
import {FiX,  FiPrinter,FiCheck,FiSearch,FiFile} from 'react-icons/fi';
import { toast } from 'react-toastify';
import { useHistory } from "react-router-dom";
import ModalPrintEditor from '../Editor/ModalPrintEditor';
import { Search } from 'react-bootstrap-icons';
import ModalImagem from '../Modal/ModalImagem';
import ModalPDFViewer from '../Modal/ModalPDFViewer';
import { AbrirPDFEmNovaAba } from "../../util/File";
import Compressor from 'compressorjs';
import { toBase64,replaceType } from '../../util/Imagem';

const headerProps = {
    icon: 'comments',
    title: 'Autorização de Reserva'
  }

export default function AutorizaReserva() {

 const [agendamentos,setAgendamentos]=useState([]);
 const history = useHistory();
 const [termo,setTermo] = useState({});
 const [pagamento,setPagamento]= useState("");
 const [agendaSelecionada,setAgendaSelecionada]=useState({})
 const [showPostModal, setShowPostModal] = useState(false);
 const [showImagemModal, setShowImagemModal] = useState(false);
 const [showPDFModal, setShowPDFModal] = useState(false);
 const [templateEmailAutorizacao,setTemplateEmailAutorizacao]= useState({});
 const [templateEmailCancelamento,setTemplateEmailCancelamento]= useState({});
 const hiddenFileInput = useRef(null);

 function togglePostModal(item) {
  setShowPostModal(!showPostModal);
    let data = format(parse(item.data,"yyyy-MM-dd'T'HH:mm:ss",new Date()),'dd/MM/yyyy HH:mm');
  setTermo({regra: item.termo.replace('@Unidade',item.unidade).replace('@Bloco',item.bloco).replace("@Data",data.split(' ')[0]).replace('@Horario',data.split(' ')[1]).replace('@Responsavel',item.responsavel)})
  }


  function toggleImagemModal(item,tipo) {

    type = tipo;
    
    if(type.includes('pdf') === false){
      setShowImagemModal(!showImagemModal);
    }else {
      AbrirPDFEmNovaAba(item)
    }
      let data = item
      setPagamento(data)
    }

    function ConvertBase64(base64){
                const raw = window.atob(base64);
          const rawLength = raw.length;
          const blobArray = new Uint8Array(new ArrayBuffer(rawLength));

          for (let i = 0; i < rawLength; i++) {
              blobArray[i] = raw.charCodeAt(i);
          }

          const blob = new Blob([blobArray], {type: 'application/pdf'});

        const url =  window.URL.createObjectURL(blob)

        var printWindow = window.open(url, '', 'width=800,height=500');
        printWindow.print()
    }

  const Print = (item) => {     
    //console.log('print');
    let data = format(parse(item.data,"yyyy-MM-dd'T'HH:mm:ss",new Date()),'dd/MM/yyyy HH:mm');
    let termo = item.termo.replace('@Unidade',item.unidade).replace('@Bloco',item.bloco).replace("@Data",data.split(' ')[0]).replace('@Horario',data.split(' ')[1]).replace('@Responsavel',item.responsavel);
    const printWindow = window.open("", "", "height=1000,width=1000");
    printWindow.document.write(termo);
    printWindow.print();
    //window.print();
   //document.body.innerHTML = originalContents; 
  }

     async function buscaAgendamentoTermo() {
     const agen = await get('/EspacoAgenda/v1/Empresa/'+empresa_Id);

        if(agen !== undefined){
            setAgendamentos(agen);
        }
     }

     async function buscaAgendamentoPagamento() {
      const agen = await get('/EspacoAgenda/v1/Empresa/Pagamento/'+empresa_Id);

         if(agen !== undefined){
             setAgendamentos(agen);
         }
      }

     async function BuscaTemplateAutorizacao(idEmpresa) {
      let result =await get('/Template/v1?tipo=4&empresa='+idEmpresa);
      setTemplateEmailAutorizacao(result);
      return result;
     }

     async function BuscaTemplateCancelamento(idEmpresa) {
      let result =await get('/Template/v1?tipo=7&empresa='+idEmpresa);
      setTemplateEmailCancelamento(result);
      return result;
     }

     useEffect(() =>{
     // buscaAgendamentoTermo();
      buscaAgendamentoPagamento();
      
        BuscaTemplateAutorizacao(empresa_Id);
        BuscaTemplateCancelamento(empresa_Id);
     },[])

    function renderForm() {
        return (
            <div className="form">
              <div id="botao">
                  <button className="btn btn-primary" onClick={e => history.push('/CadastroEspaco')}>
                    Espaços
                  </button>
                  <button className="btn btn-success" style={{marginLeft:10}} onClick={e => history.push('/Reserva/Agendamento')}>
                    Agendamentos
                  </button>
                  <button className="btn btn-warning" style={{marginLeft:10}} onClick={e => history.push('/Reserva/Cancelada')}>
                    Status Agendamento
                  </button>
                  <button className="btn btn-danger" style={{marginLeft:10}} onClick={e => history.push('/Menu')}>
                    Sair
                  </button>
                </div>
                {showPostModal && (
                  <ModalPrintEditor
                  conteudo={termo} 
                  close={togglePostModal}
                  />
                  )
                }
                  {showPDFModal && (
                      <ModalPDFViewer
                      conteudo={{pagamento, type}} 
                      close={toggleImagemModal}
                      />
                  )}

                  {showImagemModal  &&(
   
                    <ModalImagem
                      conteudo=  { {pagamento, type}} 
                      close={toggleImagemModal}
                      />)}
                <hr/>
            {renderTable()}
        </div>
        );
        
    }

    async function autorizaReserva(item){
        await put(`/EspacoAgenda/v1/Autorizar/${item.id}`);
        toast.success('Reserva autorizada com sucesso.');
        await enviarEmail(item,1)
        buscaAgendamentoPagamento();
    }

    async function cancelarReserva(item){
      await put(`/EspacoAgenda/v1/Cancelar/${item.id}`);
      toast.success('Reserva cancelada.')
      await enviarEmail(item,2)
      buscaAgendamentoPagamento();
  }

  async function enviarEmail(item, tipo){
    let emails =[];
     emails.push('william@cisistemas.com.br');     
     let data={};
     
     if(tipo === 1) {
      templateEmailAutorizacao.template =templateEmailAutorizacao.template.replace('@responsavel',item.responsavel);
      templateEmailAutorizacao.template =templateEmailAutorizacao.template.replace('@espaco',item.espaco);
      templateEmailAutorizacao.template =templateEmailAutorizacao.template.replace('@espaco',item.espaco);
      templateEmailAutorizacao.template =templateEmailAutorizacao.template.replace('@unidade',`${item.unidade} - ${item.bloco}`);
      templateEmailAutorizacao.template =templateEmailAutorizacao.template.replace('@data',format(parse(item.data,"yyyy-MM-dd'T'HH:mm:ss",new Date()),'dd/MM/yyyy HH:mm'));
       data =  { sender:emails, subject: templateEmailAutorizacao.assunto,html: templateEmailAutorizacao.template,empresa_id: empresa_Id}
     }
     
     if(tipo === 2) {
      templateEmailCancelamento.template =templateEmailCancelamento.template.replace('@responsavel',item.responsavel);
      templateEmailCancelamento.template =templateEmailCancelamento.template.replace('@espaco',item.espaco);
      templateEmailCancelamento.template =templateEmailCancelamento.template.replace('@espaco',item.espaco);
      templateEmailCancelamento.template =templateEmailCancelamento.template.replace('@unidade',`${item.unidade} - ${item.bloco}`);
      templateEmailCancelamento.template =templateEmailCancelamento.template.replace('@data',format(parse(item.data,"yyyy-MM-dd'T'HH:mm:ss",new Date()),'dd/MM/yyyy HH:mm'));
      data =  { sender:emails, subject: templateEmailCancelamento.assunto,html: templateEmailCancelamento.template,empresa_id: empresa_Id}
    }

     let result = await post('/Email/v1',data);
    await toast.success('email enviado com sucesso');
  }

  async function AtualizaAgenda(pagamento, agendaEscolhida) {
    let agend =await get('/EspacoAgenda/v1/'+ agendaEscolhida.id)
    agend.pagamento =pagamento;
    agend.tipo =agendaEscolhida.tipo;

    await put('/EspacoAgenda/v1/'+ agendaEscolhida.id,agend)
    await toast.success('Pagamento anexado com sucesso');
    buscaAgendamentoPagamento();
  }

  function anexarComprovante(agenda){
    setAgendaSelecionada(agenda);
    hiddenFileInput.current.click(agenda);
  }

  let type='';

  async function handleFile(e ){

    if(e.target.files[0]) {
        const image = e.target.files[0];

          if(image.type === 'image/jpeg' || image.type === 'image/png' || image.type === 'application/pdf'){

                 if(image.type === 'image/jpeg' || image.type === 'image/png'){
                  new Compressor( image, {
                    quality: 0.6, // 0.6 can also be used, but its not recommended to go below.
                    success: async( compressedResult) => {
                      // compressedResult has the compressed file.
                      // Use the compressed file to upload the images to your server.        
                      type = compressedResult.type;
                      let base64 = await toBase64(compressedResult);    
                      let resp =  replaceType(base64);
                      agendaSelecionada.tipo = compressedResult.type;
                     await AtualizaAgenda(resp,agendaSelecionada);
                  },
                  });
                 }else{
                  type =image.type;
                  let base64 = await toBase64(image);  
                  let resp =  replaceType(base64);
                  agendaSelecionada.tipo = image.type;
                 await AtualizaAgenda(resp,agendaSelecionada);
                 }
          
    }
            }else{
                alert('Envie uma imagem do tipo PNG ou JPEG');
            }
}

  function templateAutorizacao(usuario){
    let temp=`<html>
    <head>
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <title>Troca de senha de usuário - Aplicativo do Condomínio - La Villete</title>
    </head>

     <body>
     <div bgcolor="#FFFFFF" marginwidth="0" marginheight="0">
  <center>
  
    <table width="640" border="0" cellpadding="0" cellspacing="0" align="center" bgcolor="#f5f5f5">
      <tr>
        <td>
          <table cellpadding="0" cellspacing="0" border="0" style="font-family:Calibri,sans-serif" width="100%">
          </table>

          <p>&nbsp;</p>
          
           <table width="640" border="0" cellpadding="0" cellspacing="0" align="center" bgcolor="#f5f5f5">
             <tr>
               <td>
                 <table align="center" cellpadding="0" cellspacing="0" style="font-family:Calibri,sans-serif" width="720">
                   <tbody>
                     <tr>
                       <td style="padding:0px">
                         <center><a href="https://pt-br.facebook.com/condominiolavillette/" target="_blank" rel="noreferrer">
                           <img alt="Condomínio La Villette" src="https://condominio.cisuporte.com.br/imagens/logolavillete.png" width="50%" height="50%" 
                           style="display:block">
                         </a></center>
                       </td>
                     </tr>
                   </tbody>
                </table>
              </td>
            </tr>
                
            <tr>
              <td>								
                <h1 style="display:block;font-size:18px;color:#363636;margin:26px 15px;line-height:130%;font-family:Calibri,sans-serif;text-align:center;font-weight:400">
                 Olá ${usuario.responsavel},</h1>
                 <h2 style="display:block;font-size:18px;color:#363636;margin:0px 25px 0px;line-height:130%;font-family:Calibri,sans-serif;text-align:center;text-transform:uppercase;font-weight:900">
                   AUTORIZAÇÃO DE RESERVA DE ESPAÇO DO(A) ${usuario.espaco.toUpperCase()}.
                 </h2>
                 <br />  
                 <h4 style="display:block;font-size:14px;color:#363636;margin:0px 25px 0px;line-height:130%;font-family:Calibri,sans-serif;text-align:center;text-transform:uppercase;font-weight:900">
                 ${usuario.unidade} - ${usuario.bloco}
                 </h4>
                 <p style="display:block;font-size:16px;color:#363636;margin:22px 25px;line-height:130%;font-family:Calibri,sans-serif;text-align:center">
                 Foi autorizado o agendamento do(a) ${usuario.espaco} para o dia ${format(parse(usuario.data,"yyyy-MM-dd'T'HH:mm:ss",new Date()),'dd/MM/yyyy HH:mm')} pela Administração.<br>
                </p>
              </td>
            </tr>
            
            <tr style="margin-top:25px">
              <td>
                <div style="margin-top:25px">
                </div>
              </td>
            </tr>
                
            <tr>
            <td>
              <a href="https://pt-br.facebook.com/condominiolavillette/" 
                target="_blank" rel="noreferrer">
                <img alt="La Villette"	src="https://condominio.cisuporte.com.br/imagens/rodape_emm_lavillette_640x96.png" width="100%" height="100%">
              </a>
            </td> 
            </tr>
          </table>
        </td>
      </tr>			
    </table>
      <p>
                  <a href="https://acessoid.com.br/" target="_blank" rel="noreferrer"> Enviado por www.acessoid.com.br </a>
                </p>
  </center>
</div>
</body>
</html>`;

    return temp;
   }

   function templateCancelamento(usuario){
    let temp=`<html>
    <head>
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <title>Troca de senha de usuário - Aplicativo do Condomínio - La Villete</title>
    </head>

     <body>
     <div bgcolor="#FFFFFF" marginwidth="0" marginheight="0">
  <center>
  
    <table width="640" border="0" cellpadding="0" cellspacing="0" align="center" bgcolor="#f5f5f5">
      <tr>
        <td>
          <table cellpadding="0" cellspacing="0" border="0" style="font-family:Calibri,sans-serif" width="100%">
          </table>

          <p>&nbsp;</p>
          
           <table width="640" border="0" cellpadding="0" cellspacing="0" align="center" bgcolor="#f5f5f5">
             <tr>
               <td>
                 <table align="center" cellpadding="0" cellspacing="0" style="font-family:Calibri,sans-serif" width="720">
                   <tbody>
                     <tr>
                       <td style="padding:0px">
                         <center><a href="https://pt-br.facebook.com/condominiolavillette/" target="_blank" rel="noreferrer">
                           <img alt="Condomínio La Villette" src="https://condominio.cisuporte.com.br/imagens/logolavillete.png" width="50%" height="50%" 
                           style="display:block">
                         </a></center>
                       </td>
                     </tr>
                   </tbody>
                </table>
              </td>
            </tr>
                
            <tr>
              <td>								
                <h1 style="display:block;font-size:18px;color:#363636;margin:26px 15px;line-height:130%;font-family:Calibri,sans-serif;text-align:center;font-weight:400">
                 Olá ${usuario.responsavel},</h1>
                 <h2 style="display:block;font-size:18px;color:#363636;margin:0px 25px 0px;line-height:130%;font-family:Calibri,sans-serif;text-align:center;text-transform:uppercase;font-weight:900">
                   CANCELAMENTO DE RESERVA DE ESPAÇO DO(A) ${usuario.espaco.toUpperCase()}.
                 </h2>
                 <br />  
                 <h4 style="display:block;font-size:14px;color:#363636;margin:0px 25px 0px;line-height:130%;font-family:Calibri,sans-serif;text-align:center;text-transform:uppercase;font-weight:900">
                 da unidade ${usuario.unidade} - ${usuario.bloco}
                 </h4>
                 <p style="display:block;font-size:16px;color:#363636;margin:22px 25px;line-height:130%;font-family:Calibri,sans-serif;text-align:center">
                 Foi cancelado o agendamento do(a) ${usuario.espaco} para o dia ${format(parse(usuario.data,"yyyy-MM-dd'T'HH:mm:ss",new Date()),'dd/MM/yyyy HH:mm')} pela Administração.<br>
                </p>
              </td>
            </tr>
            
            <tr style="margin-top:25px">
              <td>
                <div style="margin-top:25px">
                </div>
              </td>
            </tr>
                
            <tr>
            <td>
              <a href="https://pt-br.facebook.com/condominiolavillette/" 
                target="_blank" rel="noreferrer">
                <img alt="La Villette"	src="https://condominio.cisuporte.com.br/imagens/rodape_emm_lavillette_640x96.png" width="100%" height="100%">
              </a>
            </td> 
            </tr>
          </table>
        </td>
      </tr>			
    </table>
      <p>
                  <a href="https://acessoid.com.br/" target="_blank" rel="noreferrer"> Enviado por www.acessoid.com.br </a>
                </p>
  </center>
</div>
</body>
</html>`;

    return temp;
   }

    function renderTable() {
        return (
          <div className='tabela'>
                      <table>
                        <thead>
                          <tr>
                            <th scope="col" className="Data">Data</th>
                            <th scope="col" className="Unidade">Unidade</th>     
                            <th scope="col" className="Espaco">Espaço</th>
                            <th scope="col"></th>
                          </tr>
                        </thead>

                        <tbody>
                          {agendamentos === undefined ? null : agendamentos.map((agenda,index) => {
                            return (
                              <tr key={index}>
                                  <td data-label="Data">{format(parse(agenda.data,"yyyy-MM-dd'T'HH:mm:ss",new Date()),'dd/MM/yyyy HH:mm')}</td>
                                  <td data-label="Unidade">{agenda.unidade} - {agenda.bloco}</td>
                                  <td data-label="Espaço">{agenda.espaco}</td>
                                 <td  data-label="">
                                  <div >
                                  <input type="file"  onChange={e => handleFile(e)} ref={hiddenFileInput} style={{display: 'none'}}/>  
                                  </div>
                                 
                                 
                                    {agenda.pagamento !== null ? (
                                       <button className="action" id="btn1" style={{backgroundColor: '#008000' ,marginRight: "10px" }} data-toggle="tooltip" data-placement="bottom" title="Visualizar" onClick={() => toggleImagemModal(agenda.pagamento, agenda.tipo)}>
                                       <Search color="#FFF" size={17} />
                                       </button>
                                    ):(
                                      <button className="action" id="btn1" style={{backgroundColor: '#FF0000' ,marginRight: "10px" }} data-toggle="tooltip" data-placement="bottom" title="Visualizar" onClick={() => toast.warning("Comprovante de pagamento não anexado.")}>
                                       <Search color="#FFF" size={17} />
                                       </button>
                                    )}
                                  {/* <button className="action" id="btn1" style={{backgroundColor: '	#909090' ,marginRight: "10px" }} data-toggle="tooltip" data-placement="bottom" title="Editar" onClick={() => togglePostModal(agenda)}>
                                    <FiPrinter color="#FFF" size={17} />
                                    </button> */}
                                     <button className="action"  style={{backgroundColor: '#228B22' ,marginRight: "10px" }} data-toggle="tooltip" data-placement="bottom" title="Anexar Comprovante" onClick={() => anexarComprovante(agenda)}>
                                    <FiFile color="#FFF" size={17} />
                                    </button>
                                    <button className="action"  style={{backgroundColor: '#228B22' ,marginRight: "10px" }} data-toggle="tooltip" data-placement="bottom" title="Autorizar Reserva" onClick={() => autorizaReserva(agenda)}>
                                    <FiCheck color="#FFF" size={17} />
                                    </button>
                                    <button className="action"  style={{backgroundColor: '#ff0000' ,marginRight: "10px" }} data-toggle="tooltip" data-placement="bottom" title="Cancelar Reserva" onClick={() => cancelarReserva(agenda)}>
                                    <FiX color="#FFF" size={17} />
                                    </button>
                                    </td>
                                </tr>
                            );
                          })}
                                
  
  
                        </tbody>
                      </table>
  
                    </div>
        );
    }
    
    return (
        <Main {...headerProps}>
            {renderForm()}
        </Main>
    );
}