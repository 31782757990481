export const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});

export const replaceType = function(base64)  {
    
    if(base64.includes('data:image/jpeg')){
      return  base64.replace('data:image/jpeg;base64,','')
    }else if(base64.includes('data:image/png')){
        return  base64.replace('data:image/png;base64,','')
   
    }else if(base64.includes('data:application/pdf')){
        return  base64.replace('data:application/pdf;base64,','')
   
    }
}

export function dataURLtoFile(dataurl, filename) {

    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), 
        n = bstr.length, 
        u8arr = new Uint8Array(n);
        
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    
    return new File([u8arr], filename, {type:mime});
  }