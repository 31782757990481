import React,{useState,useEffect} from "react";
import { parse,format, getDate } from "date-fns"; 
import { useHistory,useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import { get, put } from '../store/config/register.js'
// import { Calendar, momentLocalizer } from "react-big-calendar";
import 'moment/locale/pt-br';
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment/moment.js";
import { login_Id,empresa_Id } from "../store/config/dadosuser.js";
import Main from "../components/template/Main.jsx";
import './ReservaEspaco.css'
import ModalAgenda from "./Modal/ModalAgenda.jsx";
import ModalAgendado from "./Modal/ModalAgendado.jsx";
import ModalRegras from "./Modal/ModalRegras.jsx";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { Calendar } from "react-modern-calendar-datepicker";
const parse_html = require('html-react-parser')

const headerProps = {
    icon: "object-ungroup",
    title: "Reserva de Espaço",
  }

const initialState ={
    visitante: {
      id: 0,


    }
  }

  const messages = {
    allDay: 'Dia Inteiro',
    previous: '<',
    next: '>',
    today: 'Hoje',
    month: 'Mês',
    week: 'Semana',
    day: 'Dia',
    agenda: 'Agenda',
    date: 'Data',
    time: 'Hora',
    event: 'Evento'
}


const myCustomLocale = {
  // months list by order
  months: [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro',
  ],

  // week days by order
  weekDays: [
    {
      name: 'Domingo', // used for accessibility 
      short: 'D', // displayed at the top of days' rows
      isWeekend: true, // is it a formal weekend or not?
    },
    {
      name: 'Segunda-Feira',
      short: 'S',
    },
    {
      name: 'Terça-Feira',
      short: 'T',
    },
    {
      name: 'Quarta-Feira',
      short: 'Q',
    },
    {
      name: 'Quinta-Feira',
      short: 'Q',
    },
    {
      name: 'Sexta-Feira',
      short: 'S',
    },
    {
      name: 'Sábado',
      short: 'S',
      isWeekend: true,
    },
  ],

  // just play around with this number between 0 and 6
  weekStartingIndex: 0,

  // return a { year: number, month: number, day: number } object
  getToday(gregorainTodayObject) {
    return gregorainTodayObject;
  },

  // return a native JavaScript date here
  toNativeDate(date) {
    return new Date(date.year, date.month - 1, date.day);
  },

  // return a number for date's month length
  getMonthLength(date) {
    return new Date(date.year, date.month, 0).getDate();
  },

  // return a transformed digit to your locale
  transformDigit(digit) {
    return digit;
  },

  // texts in the date picker
  nextMonth: 'Next Month',
  previousMonth: 'Previous Month',
  openMonthSelector: 'Open Month Selector',
  openYearSelector: 'Open Year Selector',
  closeMonthSelector: 'Close Month Selector',
  closeYearSelector: 'Close Year Selector',
  defaultPlaceholder: 'Selecionar...',

  // for input range value
  from: 'from',
  to: 'to',


  // used for input value when multi dates are selected
  digitSeparator: ',',

  // if your provide -2 for example, year will be 2 digited
  yearLetterSkip: 0,

  // is your language rtl or ltr?
  isRtl: false,
}
  
export default function Reservas(){
    const [origemEspaco, setOrigemEspaco] = useState ({});
    const [usuario,setUsuario]=useState({});
    const [data,setData]=useState(new Date())
    const history = useHistory()
    // const localizer = momentLocalizer(moment);
    const [myEventsList,setMyEventList]=useState([{ start: new Date('2023-08-08 08:00'), end: new Date('2023-08-08 14:00'), title: " (101 - A) special event" },{ start: new Date('2023-08-08 15:00'), end: new Date('2023-08-08 22:00'), title: " (101 - A) as special event" }]);
    const [selectedEvent, setSelectedEvent] = useState(undefined)
    const [modalState, setModalState] = useState(false)
    const [modalStateAgendado, setModalStateAgendado] = useState(false)
    const [modalStateRegras, setModalStateRegras] = useState(false)
    const [item,setItem]=useState({})
    const [agendado, setAgendado]=useState({})
    const [espaco,setEspaco] = useState({});
    const [horarioPadrao,setHorarioPadrao] = useState([]);
    const [regra,setRegra] =useState('');
    const {id}=useParams();
    const [agendamentos,setAgendamentos]= useState([]);
    const [agendamentosPaint,setAgendamentosPaint]= useState([]);
    const [diaHorario,setDiaHorario]= useState([]) 
    const [horario, setHorario] = useState(false);
    const [horarios, setHorarios]=useState([]);
    const [templateEmail,setTemplateEmail]= useState({});
    const [emailParam,setEmailParam]= useState({});
    const [regraItem,setRegraItem]=useState({espaco: id, regra: "" ,espacoNome: ''});

    

  const handleDisabledSelect = disabledDay => {
    console.log('Tried selecting a disabled day', disabledDay);
  };


  const defaultValue = {
    year: data.getFullYear(),
    month:data.getMonth()+1,
    day: data.getDate(),
  };
  const [selectedDay, setSelectedDay] = useState(defaultValue);
  const disabledDays = [];

    useEffect(() => {
      buscaEspaco();
    },[])

    useEffect(() => {
      FindHorarios();
    },[])

    useEffect(() => {
      buscaAgendamento(data);
    },[])

    useEffect(()=>{
      VerificaUsuario();
      buscaRegras();
      buscaHorarioPadrao();
      BuscaTemplate(empresa_Id);
      BuscaEmailParam(empresa_Id)
     
    },[])



    async function buscaHorarioPadrao() {
     const hors = await get('/EspacoPeriodoPadrao/v1/Espaco/'+ id);
     if(hors !== undefined){
      setHorarioPadrao(hors)
    }
    }

    async function buscaAgendamentoUnidade(mes, ano) {
      const agend = await get(`/EspacoAgenda/v1/Espaco/Unidade?espaco_id=${id}&unidade=${usuario.unidade_Id}&mes=${mes}&ano=${ano}`);
      return agend;
     }

    async function BuscaEmailParam(idEmpresa) {
      let result = await get('/ParamEmailResp/v1/'+idEmpresa);
      setEmailParam(result);

      return result;
  }

    async function BuscaTemplate(idEmpresa){
      let result =await get('/Template/v1?tipo=6&empresa='+idEmpresa);
     // console.log(result);
      setTemplateEmail(result);
      return result;
     }


      function handleSelectedEvent (e){
        togglePostModalAgendado(e);
      }

    async  function SelectedDayChange(e){
        let  dados = { data: new Date(e.year,(e.month-1),e.day)}
        let hoje = new Date().setHours(0,0,0,0)
        hoje =new Date(hoje);
     
       let mesEscolhido =e.month-1;
       let evento = [];

       if(data.getMonth() !== mesEscolhido){
         setData(new Date(e.year,(e.month-1),e.day)) 
         evento =  await buscaAgendamento(new Date(e.year,(e.month-1),e.day));
        }


        hoje.setDate(hoje.getDate() + parseInt(espaco.antecedencia_Maxima));
        let Permitido =hoje >= dados.data? true: false;
        let padrao = horarioPadrao.filter( f => f.dia_Semana === dados.data.getDay()+1);
        dados.padrao =padrao;
        hoje = new Date().setHours(0,0,0,0);

        Permitido = Permitido === false?false : dados.data >= hoje ? true: false;

        let MesAgendado =[];
            if(espaco.flg_Controle_Periodo === 'M'){
              MesAgendado = await  buscaAgendamentoUnidade(e.month,e.year);
            }

            if(MesAgendado.length > 0){
           
            let agendaDia =  parse(MesAgendado[0].data,"yyyy-MM-dd'T'HH:mm:ss", new Date())
            Permitido =  e.day === agendaDia.getDate() ? true : false;
            }


        if(Permitido && padrao.length > 0) {
          await Horarios(dados.data.getDay()+1, dados)
          
         if (dados.Horarios === undefined){
          //Eventos com um unico horario no dia

        let dia = evento.length === 0? agendamentos.filter(f => format(parse(f.inicio.substring(0,10),'yyyy-MM-dd', new Date()),'dd/MM/yyyy') === format(dados.data,'dd/MM/yyyy') ): evento.filter(f => format(parse(f.inicio.substring(0,10),'yyyy-MM-dd', new Date()),'dd/MM/yyyy') === format(dados.data,'dd/MM/yyyy') );
          if (dia.length === 0) {
          setSelectedDay(e)
         history.push(`/ReservaEspaco/Agenda/${id}/${colocazero(e.day)}-${colocazero(e.month)}-${e.year}`)
        } else {
          let login =dia.filter(f => f.login_Id === login_Id);
  
          if(login.length === 0){
            await toast.warning('Já existe um agendamento para este horário');
          }else {
  
            let dia =  evento.length === 0? agendamentos.filter(f => format(parse(f.inicio.substring(0,10),'yyyy-MM-dd', new Date()),'dd/MM/yyyy') === format(dados.data,'dd/MM/yyyy') && f.login_Id == usuario.id):evento.filter(f => format(parse(f.inicio.substring(0,10),'yyyy-MM-dd', new Date()),'dd/MM/yyyy') === format(dados.data,'dd/MM/yyyy') && f.login_Id == usuario.id);
           history.push(`/ReservaEspaco/Agendado/${id}/${dia[0].id}`)
          }
          
        }
       
         }else {
          //Eventos com mais de um horario no dia
      
          let dia = evento.length === 0? agendamentos.filter(f => format(parse(f.inicio.substring(0,10),'yyyy-MM-dd', new Date()),'dd/MM/yyyy') === format(dados.data,'dd/MM/yyyy')): evento.filter(f => format(parse(f.inicio.substring(0,10),'yyyy-MM-dd', new Date()),'dd/MM/yyyy') === format(dados.data,'dd/MM/yyyy'));
          let login =dia.filter(f => f.login_Id === login_Id);
          if(dia.length <dados.Horarios.length) {
          
            if(login.length === 0){
              setSelectedDay(e)

              history.push(`/ReservaEspaco/Agenda/${id}/${colocazero(e.day)}-${colocazero(e.month)}-${e.year}`)
  
            }else {
              let ev ={ id:login[0].id ,espaco_id: id, unidade_id: login[0].unidade_Id ,start: new Date(login[0].inicio.replace('T',' ')), end: new Date(login[0].fim.replace('T',' ')), title: `${login[0].unidade}`}
            //  togglePostModalAgendado(ev);
            
            let dia =  evento.length === 0? agendamentos.filter(f => format(parse(f.inicio.substring(0,10),'yyyy-MM-dd', new Date()),'dd/MM/yyyy') === format(dados.data,'dd/MM/yyyy') && f.login_Id == usuario.id):evento.filter(f => format(parse(f.inicio.substring(0,10),'yyyy-MM-dd', new Date()),'dd/MM/yyyy') === format(dados.data,'dd/MM/yyyy') && f.login_Id == usuario.id);
              history.push(`/ReservaEspaco/Agendado/${id}/${dia[0].id}`)
            }
          
          }else {
  
            if(login.length === 0){
              await toast.warning('Não existe horários disponíveis para este dia.');
            }else {
    
            
              let dia =  evento.length === 0? agendamentos.filter(f => format(parse(f.inicio.substring(0,10),'yyyy-MM-dd', new Date()),'dd/MM/yyyy') === format(dados.data,'dd/MM/yyyy') && f.login_Id == usuario.id):evento.filter(f => format(parse(f.inicio.substring(0,10),'yyyy-MM-dd', new Date()),'dd/MM/yyyy') === format(dados.data,'dd/MM/yyyy') && f.login_Id == usuario.id);

            history.push(`/ReservaEspaco/Agendado/${id}/${dia[0].id}`)
            }
            
          }
          
         }
        } else{
          if(MesAgendado.length > 0){
            toast.warning('Já existe um agendamento para sua unidade este mês.');
          }else {
            toast.warning('Dia não permitido para agendamento.');
          }
         
        }
  
       
      }

      async function VerificaUsuario(){

        if(login_Id > 0){
          const  result = await  get(`/Login/v1/${login_Id}`);
          setUsuario(result)
       }
       
      }

      async function buscaEspaco() {
       const esp = await get('/Espaco/v1/'+ id);
        if(esp !== undefined){
          setEspaco(esp)
          setRegraItem({espaco: id, regra: "" ,espacoNome: esp.nome}) ;
        }
      }

      function colocazero(valor){
        if(valor < 10){
          return '0'+ valor;
        }else{
          return valor;
        }

      }

      async function buscaRegras() {
        const doc =await get('/EspacoDocumento/v1?IdEspaco='+id+'&Tipo=R');

        if(doc !== ''){
            setRegra(doc.texto)
            setRegraItem({espaco: id, regra: doc.texto,espacoNome:regraItem.espacoNome }) ;
        }
    }

      async function buscaAgendamento(data) {
        const even =await get(`/EspacoAgenda/v1/Espaco?espaco_id=${id}&mes=${data.getMonth()+1}&ano=${data.getFullYear()}`)
        if(even !== undefined) {
          let eventos =[];
          even.map((item) => {
            const date = parse(item.inicio,"yyyy-MM-dd'T'HH:mm:ss", new Date());
            if(item.unidade_Id === usuario.unidade_Id){
              eventos.unshift( {year: date.getFullYear(), month: date.getMonth()+1, day: date.getDate(), className: 'yellowDay' });
            }else{
              eventos.unshift( {year: date.getFullYear(), month: date.getMonth()+1, day: date.getDate(), className: 'redDay' });
            }
          });

          setAgendamentosPaint(eventos);
          setAgendamentos(even);
            return even;
        }
        

        //
      }

      function togglePostModal(item){
       
          item.espaco_id = id;
          item.usuario = usuario;
          item.login_id = login_Id;
          item.espaco_nome = espaco.nome;
          item.termo = espaco.termo;
          item.template_email = templateEmail;
          item.email_param = emailParam
         
          setItem({...item})   
        setModalState(!modalState) //trocando de true pra false
    }

    function togglePostModalAgendado(item) { 
      setAgendado(item)
      item.usuario_unidade = usuario.unidade_Id;
      setModalStateAgendado(!modalStateAgendado);
      
      
    }

    function togglePostModalRegras(item) { 
      setModalStateRegras(!modalStateRegras);
    }
      
    function clear() {
      history.push ('/Reservas')
    }

    function save () {
      
    }

    async function Horarios(diaSemana,dados){

       dados.Horario = false;
       const hors= await get(`/EspacoPeriodoHora/v1/Horarios?espaco_id=${id}&dia_semana=${diaSemana}`)
       if(hors.length > 0){
              setHorario(true);
              dados.Horario = true;

              hors.unshift({id: 0, hora_Inicio: '', hora_Fim: ''})
              dados.Horarios = hors;
              setHorarios(hors)
          }
      }


      async function FindHorarios(){

       const hors= await get(`/EspacoPeriodoHora/v1/Espaco/${id}`)
        if(hors.length > 0){
         setDiaHorario(hors)
           }

       }

      const calendarStyle =  (date) => {
        let currentDate = `${new Date().getDate()} ${new Date().getMonth()+1} ${new Date().getFullYear()}`
        let dias =diaHorario.filter(f => f.dia_Semana === date.getDay()+1);
        let agenda = agendamentos.filter(f => format(parse(f.inicio.substring(0,10),'yyyy-MM-dd', new Date()),'dd/MM/yyyy') === format(date,'dd/MM/yyyy'));
        

        if(agenda.length > 0) {
          if(dias.length === 0){
            let meuagendamento = agenda.filter(f => f.login_Id ===login_Id);

            if(meuagendamento.length === 0){
              return {
                style: {
                  backgroundColor: '#ff0000', 
                  border: '1px solid gray',
                  margin: 0,
                  padding: 0
                }
              }
            } else {
              return {
                style: {
                  backgroundColor: '#008000', 
                  border: '1px solid gray',
                  margin: 0,
                  padding: 0
                }
              }
            }
           
          }else {
            let meuagendamento = agenda.filter(f => f.login_Id ===login_Id);
            if(meuagendamento.length === 0){
              if(agenda.length < dias.length) {
                return {
                  style: {
                    backgroundColor: '#ffff00', 
                    border: '1px solid gray',
                    margin: 0,
                    padding: 0
                  }
                }
              }else {
                return {
                  style: {
                    backgroundColor: '#ff0000', 
                    border: '1px solid gray',
                    margin: 0,
                    padding: 0
                  }
                }
              }
             
            }else {
              return {
                style: {
                  backgroundColor: '#008000', 
                  border: '1px solid gray',
                  margin: 0,
                  padding: 0
                }
              }
            }
            
          }
          
        }    
    }

    function renderFormRegras(item){
      return (
    
            <div>
                  <div className="titulo">
                      <i className="fa fa-info-circle"></i>                   
                      <label >{item.espacoNome}</label>
                    </div>
                    <div>
                    </div>
                    <hr />
                    <div >
                    <span style={{overflow: 'hidden', textOverflow: 'ellipsis'}}> 
                                  
                        {parse_html(item.regra)}
                         </span>
                    </div> 
            </div>

      );
    }

    function renderForm(){
      
        return (
            <div>

{/*
                <div className="titulo">
                  Detalhes da Reserva
                </div>
        */}
                <div className="tituloEspaco">
                  Local: {espaco.nome}
                </div>

                <div className="infoEspaco">
                  <div className="infoRegras">
                    <label onClick={(e) => {togglePostModalRegras()}}><i className={`fa fa-info-circle`}></i>Regras de uso</label>
                  </div>
                  <label><i className={`fa fa-users`}></i>Capacidade: {espaco.capacidade}  pessoas </label>
                  {espaco.flg_Taxa_Reserva && (
                  <label><i className={`fa fa-usd`}></i>Taxa de reserva: R$ {espaco.taxa}</label>
                  )}

                  {espaco.desistencia > 72 ? (
                     <label><i className={`fa fa-times`}></i>Cancelamento: máximo {espaco.desistencia/24} dias antes do evento</label>
                  ):
                  (
                  <label><i className={`fa fa-times`}></i>Cancelamento: {espaco.desistencia} horas antes do evento </label>
                  )
                  }
                  
                </div>

                {modalState && (
                <ModalAgenda 
                conteudo={item} 
                close={togglePostModal} />)}

                {modalStateAgendado && (
                  <ModalAgendado
                  conteudo={{agenda: agendado, espaco: espaco}} 
                  close={togglePostModalAgendado}
                  />
                )}
                {modalStateRegras && (
                  <ModalRegras
                  conteudo={{espaco: id, regra: regra ,espacoNome: espaco.nome}} 
                  close={togglePostModalRegras}
                  />
                )}
                <hr/>
                {regraItem.espacoNome !== undefined ?renderFormRegras(regraItem): null}
                <hr/>
                <div className="calendario">
                <Calendar
                    value={selectedDay}
                    onChange={SelectedDayChange}
                    disabledDays={disabledDays} // here we pass them
                    onDisabledDayError={handleDisabledSelect} // handle error
                    customDaysClassName={agendamentosPaint}
                    shouldHighlightWeekends
                    locale={myCustomLocale}
                  />
                </div>
                
            </div>

        );
    }
    return (
    <Main {...headerProps}>
        {renderForm()}
    </Main>
    );
}