import React,{useEffect,useState} from "react";
import Main from "../../components/template/Main";

import {get, post} from '../../store/config/register';
import { empresa } from "../../store/config/dadosuser"; 
import { getLocalStorage,setLocalStorage } from "../../store/config/storage";
import { useHistory } from "react-router-dom";
import { toast } from 'react-toastify'; 

import { secretKey } from "../../global";
import CryptoJS from "crypto-js";
import { Buffer } from 'buffer';

const headerProps = {
    icon: "building",
    title: "Condomínio",
    subtitle: "Unidade"
}

export default function Condominio() {

    const [empresas,setEmpresas]=useState([]);
    const [blocos,setBlocos] =useState([]);
    const [unidades,setUnidades]= useState([]);
    const [usuario,setUsuario]=useState({});
    const link =window.location.href.replace("/Condominio","/Confirmar");
    const [templateEmail,setTemplateEmail]= useState({});
    

    const history = useHistory();
    let user =getLocalStorage('user');
    const atualizacao =getLocalStorage('update');
    useEffect(()=>{             
        if(user !== undefined){
            setUsuario({...usuario, ...user});
        }else {
            history.push("/");
        }        
    },[])

    useEffect(()=>{
        buscaEmpresas();
        getEmpresa();
    },[empresa]);

    

    function getEmpresa(){
       setUsuario({...usuario, Empresa_Id: parseInt(empresa),Nome: user.Nome ,Senha: user.Senha, Email: user.Email })

    }

    useEffect(()=>{
        if(empresa === undefined){
            window.location.reload();
        }
        buscaBloco(usuario.Empresa_Id);
        BuscaTemplate(usuario.Empresa_Id);
    },[usuario.Empresa_Id]);

    useEffect(()=>{
        buscaUnidade(usuario.Bloco_Id);
    },[usuario.Bloco_Id]);

    if( atualizacao !== undefined){
        setLocalStorage('update',true);
        window.location.reload();
    }

    async function BuscaTemplate(idEmpresa){
        let result =await get('/Template/v1?tipo=5&empresa='+idEmpresa);
       // console.log(result);
        setTemplateEmail(result);
        return result;
       }

    function trocaEmpresa(e) {
        setUsuario({...usuario, Empresa_Id: parseInt(e.target.value)})
    }

    function trocaBloco(e) {
        setUsuario({...usuario, Bloco_Id: parseInt(e.target.value)})
    }

    function trocaUnidade(e) {
        setUsuario({...usuario, Unidade_Id: parseInt(e.target.value)})
    }

    function renderSelectEmpresa(){    
        if(empresas !== undefined){
            return empresas.map(empresa =>{
                return (<option key={empresa.id} value={empresa.id}>{empresa.nome}</option>)
            })
        }        
    }

    function renderSelectBloco(){    
        if(blocos !== undefined){
            return blocos.map(bloco =>{
                return (<option key={bloco.id} value={bloco.id}>{bloco.nome}</option>)
            })
        }        
    }

    function renderSelectUnidade(){    
        if(unidades !== undefined){
            return unidades.map(unidade =>{
                return (<option key={unidade.id} value={unidade.id}>{unidade.nome}</option>)
            })
        }        
    }
     function buscaEmpresas(){
            const emps =  get("/Empresa/v1");
            if(emps != null){
                emps.then(resp => {
                    resp.unshift({id:0, nome:""})
                    setEmpresas(resp);
               })
            }
    }

    function buscaBloco(empresa_id){
        const blocs =   get(`/Bloco/v1/Empresa/${empresa_id}`);
        if(blocs != null){
              blocs.then(resp => {
                resp.unshift({id:0, nome:""})
                setBlocos(resp);
           })
        }
    }

    function buscaUnidade(bloco_id){
        const unids =  get(`/Unidade/v1/Bloco/${bloco_id}`);
        if(unids != null){
            usuario.Unidade_Id =0
            unids.then(resp => {
                
                resp.unshift({id:0, nome:""})
                setUnidades(resp);
           })
        }else {
           
        }
    }

    function cancel(){
        history.push("/");
    }


    function ValidaBLocoUnidade(){
        if(usuario.Bloco_Id === undefined || usuario.Bloco_Id === 0){
            toast.warning('selecione um bloco');
            return false;
        }

        if(usuario.Unidade_Id === undefined || usuario.Unidade_Id === 0){
            toast.warning('selecione uma unidade');
            return false;
        }
        return true;
    }

    async function save(){
       if(ValidaBLocoUnidade()) {
         const  result = await post('/Login/v1',usuario);
         if(result !== null){
          usuario.Id=result.id;
          toast.success('conta salva com sucesso!');
          enviarEmail(result.id);
          localStorage.clear();

          setLocalStorage("messagem",JSON.stringify({message:"Foi enviado um email de confirmação. Entre no email e clique no link para confirmar o seu cadastro."}),5)
         history.push(`/Autorizacao/${user.Nome}`);
         }
       } 
        
    }

    async function enviarEmail(Id){
        let emails =[];
         emails.push(user.Email);     
         templateEmail.template =  templateEmail.template .replace("@link",link+"/"+utf8ToHex(user.Email+"/"+Id));
         templateEmail.template  = templateEmail.template.replace("@nome",user.Nome);
         let data =  { sender:emails, subject: templateEmail.assunto,html: templateEmail.template,empresa_id: usuario.Empresa_Id}
         let result =await post('/Email/v1',data);
        await toast.success('email enviado com sucesso');
      }
    function render(){
        return(
            <div>
            <h2>Selecione o Condomínio:</h2>
            <div className="nome-visitante">
                <div className="form-group">
                  <label>Condomínio:</label>
                  <select disabled name="Condominio" 
                    className="form-control"
                    value={usuario.Empresa_Id}  
                    onChange={trocaEmpresa} >{renderSelectEmpresa()} </select>
                </div>
            </div> 

            <div className="nome-visitante">
                <div className="form-group">
                  <label>Bloco:</label>
                  <select  name="Bloco" 
                    className="form-control"  
                    value={usuario.Bloco_Id}  
                    onChange={trocaBloco}>{renderSelectBloco()} </select>
                </div>
            </div> 

            <div className="nome-visitante">
                <div className="form-group">
                  <label>Unidade:</label>
                  <select  name="Unidade" 
                    className="form-control"  
                    value={usuario.Unidade_Id}  
                    onChange={trocaUnidade} >{renderSelectUnidade()} </select>
                </div>
            </div>
            <hr />

            <div id="botao">
                  <button className="btn btn-primary" onClick={e => save()}>
                    Salvar
                  </button>
                  <button className="btn btn-secondary ml-2" onClick={e => cancel()}>
                    Cancelar
                  </button>
            </div>
        </div>
        );
    }

    const convert = (from, to) => str => Buffer.from(str, from).toString(to)
    const utf8ToHex = convert('utf8', 'hex');
    const hexToUtf8 = convert('hex','utf8');

    function template(usuario,Id){
        let temp = `<html>
        <head>
            <meta charset="utf-8">
            <meta name="viewport" content="width=device-width, initial-scale=1">
            <title>Confirmação de Cadastro - Aplicativo do Condomínio - La Villete</title>
        </head>
        <body>
            <div bgcolor="#FFFFFF" marginwidth="0" marginheight="0">
                <center>
                    <table width="640" border="0" cellpadding="0" cellspacing="0" align="center" bgcolor="#f5f5f5">
                        <tr>
                            <td>
                                <table cellpadding="0" cellspacing="0" border="0" style="font-family:Calibri,sans-serif" width="100%">
                                </table>
        
                                <p>&nbsp;</p>
        
                                <table width="640" border="0" cellpadding="0" cellspacing="0" align="center" bgcolor="#f5f5f5">
                                    <tr>
                                        <td>
                                            <table align="center" cellpadding="0" cellspacing="0" style="font-family:Calibri,sans-serif" width="720">
                                                <tbody>
                                                    <tr>
                                                        <td style="padding:0px">
                                                            <center><a href="https://pt-br.facebook.com/condominiolavillette/" target="_blank" rel="noreferrer">
                                                                <img alt="Condomínio La Villete" src="https://condominio.cisuporte.com.br/imagens/logolavillete.png" width="50%" height="50%" 
                                                                style="display:block">
                                                            </a></center>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
        
                                    <tr>
                                        <td>
                                            <h1 style="display:block;font-size:18px;color:#363636;margin:26px 15px;line-height:130%;font-family:Calibri,sans-serif;text-align:center;font-weight:400">
                                            Olá ${user.Nome},</h1>
        
                                            <h2 style="display:block;font-size:18px;color:#363636;margin:0px 25px 0px;line-height:130%;font-family:Calibri,sans-serif;text-align:center;text-transform:uppercase;font-weight:900">
                                                seja bem-vindo(a) ao Aplicativo do Condomínio
                                            </h2>
        
                                            <p style="display:block;font-size:16px;color:#363636;margin:22px 25px;line-height:130%;font-family:Calibri,sans-serif;text-align:center">
                                                Você está recebendo este e-mail para <b>confirmar o seu cadastro no Aplicativo do Condomínio</b>.<br>
                                            </p>
        
                                            <table width="320" border="0" cellpadding="0" cellspacing="0" align="center">
                                                <tr bgcolor="#2A4C1D">
                                                    <td style="display:table-cell;font-size:12px;font-family:Arial,sans-serif; 
                                                            text-align:center; line-height:100%; text-transform:uppercase; 
                                                            padding:15px 0px;color:#ffffff">
                                                        
                                                        <a href="${link+"/"+utf8ToHex(usuario.Email+"/"+Id)}" 
                                                        style="text-decoration:none;font-family:Raleway,sans-serif;font-weight:500;
                                                        color:#ffffff" target="_blank" rel="noreferrer">CONFIRME AQUI SEU CADASTRO</a>
                                                    </td>
                                                </tr>
                                            </table>
                                            <br>
                                            <br>
                                            <br>
                                            <center> <i>Caso você não tenha solicitado o cadastro, favor desconsiderar este e-mail. </i>
                                            </center>
                                            <!--p style="display:block;font-size:16px;color:#363636;margin:22px 25px;line-height:130%;font-family:Calibri,sans-serif;text-align:center">
                                                <i>Caso você não tenha solicitado o cadastro, favor desconsiderar este e-mail. </i>
                                            </p-->
                                        </td>
                                    </tr>
        
                                    <tr style="margin-top:25px">
                                        <td>
                                            <div style="margin-top:25px">
                                            </div>
                                        </td>
                                    </tr>
                                    
                                    <tr>
                                        <table align="center" border="0" cellpadding="0" cellspacing="0" style="background-color:whitesmoke" width="640">
                                            <tbody>
                                                <tr>
                                                    <td width="390" heigth="96">
                                                        <a href="https://pt-br.facebook.com/condominiolavillette/" 
                                                        target="_blank" rel="noreferrer">
                                                        <img alt="La Villette"	src="https://condominio.cisuporte.com.br/imagens/rodape_emm_lavillette.png" 
                                                        style="display:inline-block;float:left;width:100%;height:auto;border:0px;padding:0px 0px;margin:0px 0px">
                                                    </a></td>
                                                    <td width="58" heigth="96"><img alt="" src="https://condominio.cisuporte.com.br/imagens/vazio.png" 
                                                        style="display:inline-block;float:left; width:100%;height:auto;border:0px;padding:0px 0px;margin:0px 0px"></td>
                                                    <td width="58" heigth="96"><img alt="" src="https://condominio.cisuporte.com.br/imagens/vazio.png" 
                                                    style="display:inline-block;float:left; width:100%;height:auto;border:0px;padding:0px 0px;margin:0px 0px"></td>
                                                    <td width="58" heigth="96">
                                                        <a href="https://pt-br.facebook.com/condominiolavillette/" target="_blank" rel="noreferrer">
                                                        <img alt="Facebook" src="https://condominio.cisuporte.com.br/imagens/facebook.png" 
                                                        style="display:inline-block;float:left; width:100%;height:auto;border:0px;padding:0px 0px;margin:0px 0px">
                                                        </a>
                                                    </td>
                                                    <td width="58" heigth="96"><img alt="" src="https://condominio.cisuporte.com.br/imagens/vazio.png" 
                                                    style="display:inline-block;float:left; width:100%;height:auto;border:0px;padding:0px 0px;margin:0px 0px"></td>
                                                </tr>
                                                <tr>
                                                    <table cellpadding="0" cellspacing="0" border="0" style="font-family:Calibri,sans-serif" width="100%">
                                                    </table>
                                                    <p>&nbsp;</p>
                                                </tr>
        
                                            </tbody>
                                        </table>
                                    </tr>
        
                                
                                </table>
                            </td>
                        </tr>
                        
                        <tr>
                            <td>
                                <center>
                                <p>
                                    <a href="https://acessoid.com.br/" target="_blank" rel="noreferrer"> Enviado por www.acessoid.com.br</a>
        
                                    <!--br>
                                        
                                        Se deseja não receber mais mensagens como esta, clique aqui.
                                    -->   
                                </p>
                            </td>
                        </tr>
                        
                    </table>
                </center>
            </div>
            
            
        </body>
        </html>`;

        return temp;
    }

    return (
        <Main {...headerProps}>
            {render()}
        </Main>
    );
}