import React,{useState,useEffect} from "react";
import Main from "../../components/template/Main";
import {FiX,FiEdit2,FiLink} from 'react-icons/fi';
import {post,get} from '../../store/config/register';
import { useHistory } from "react-router-dom";
import { empresa_Id } from "../../store/config/dadosuser";
import 'font-awesome/css/font-awesome.min.css'
import './CadastroEspaco.css'

const headerProps = {
  icon: 'object-ungroup',
  title: 'Cadastro de Espaços'
}

export default function CadastroEspaco() {
    const history = useHistory(); 
    const [espaco,setEspaco]=useState('');
    const [espacos,setEspacos]=useState([]);
    const [showPostPesquisa, setShowPostPesquisa] = useState(false);
    const [representante, setRepresentante]=useState({})

    useEffect(()=>{
      buscaDados();
    },[])

 async function buscaDados() {
      const esp = await get('/Espaco/v1/Empresa/'+empresa_Id);
      if(esp !== undefined) {
        setEspacos(esp)
    } 
  }

  function novo() {
    history.push ('/NovoEspaco')
  }

  function sair () {
    history.push ('/Menu')
  }

  function editEspaco(id) {
    history.push ('/NovoEspaco/'+id)
  }

  function regulamento () {
    alert ('abrir tela para incluir editor html')
  }

  function renderTable() {
      return (
        <div className='tabela'>
                    <table>
                      <thead>
                        <tr>
                          <th scope="col" className="Nome">Nome</th>
                          <th scope="col"></th>
                        </tr>
                      </thead>

                      <tbody>
                        {espacos === undefined ? null : espacos.map((espaco,index) => {
                          return (
                            <tr key={index}>
                                <td data-label="Nome">{espaco.nome}</td>
                                <td  data-label="">
                                <button className="action" id="btn1" style={{backgroundColor: '#F99E08' ,marginRight: "10px" }} data-toggle="tooltip" data-placement="bottom" title="Editar" onClick={() => {editEspaco(espaco.id)}}>
                                  <FiEdit2 color="#FFF" size={17} />
                                  </button>
                                  <button className="action" style={{backgroundColor: '#ff0000',marginRight: "10px" ,marginTop: "10px" }} data-toggle="tooltip" data-placement="bottom" title="Excluir Espaço" >
                                  <FiX color="#FFF" size={17} />
                                  </button>
                                  </td>
                              </tr>
                          );
                        })}
                              


                      </tbody>
                    </table>

                  </div>
      );
  }

    function renderForm(){

        return(
            <div className="main-form-cadastro">
                <div className="row1">
                  <button className="btn btn-primary" onClick={e => novo()}>
                    Novo
                  </button>
                  <button className="btnSairCad btn-primary" onClick={e => sair()}>
                    Sair
                  </button>
                </div>



                <hr/>
                {renderTable()}
                
            </div>
        );
    }
    return(
        <Main {...headerProps}>
            {renderForm()}
        </Main>
    );

}