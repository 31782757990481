import React,{useState,useEffect} from "react";
import Main from "../../components/template/Main";
import JoditEditor from "jodit-react";
import './CadastroAviso.css';
import {get,post} from '../../store/config/register';
import { toast } from 'react-toastify';
import { format} from 'date-fns';
import { empresa_Id,login_Id } from "../../store/config/dadosuser";
import { useHistory } from "react-router-dom";

const headerProps = {
    icon: 'comments',
    title: 'Cadastro de Avisos'
  }

  const copyStringToClipboard = function(str) {
    var el = document.createElement("textarea");
    el.value = str;
    el.setAttribute("readonly", "");
    el.style = { position: "absolute", left: "-9999px" };
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
  };
  
  const facilityMergeFields = [
    "FacilityNumber",
    "FacilityName",
    "Address",
    "MapCategory",
    "Latitude",
    "Longitude",
    "ReceivingPlant",
    "TrunkLine",
    "SiteElevation"
  ];
  const inspectionMergeFields = [
    "InspectionCompleteDate",
    "InspectionEventType"
  ];
  const createOptionGroupElement = (mergeFields, optionGrouplabel) => {
    let optionGroupElement = document.createElement("optgroup");
    optionGroupElement.setAttribute("label", optionGrouplabel);
    for (let index = 0; index < mergeFields.length; index++) {
      let optionElement = document.createElement("option");
      optionElement.setAttribute("class", "merge-field-select-option");
      optionElement.setAttribute("value", mergeFields[index]);
      optionElement.text = mergeFields[index];
      optionGroupElement.appendChild(optionElement);
    }
    return optionGroupElement;
  }
  const buttons = [
    "undo",
    "redo",
    "|",
    "bold",
    "strikethrough",
    "underline",
    "italic",
    "|",
    "align",
    "|",
    "ul",
    "ol",
    "outdent",
    "indent",
    "|",
    "font",
    "fontsize",
    "brush",
    "paragraph",
    {
      name: "insertMergeField",
      tooltip: "Insert Merge Field",
      iconURL: "images/merge.png",
      popup: (editor, current, self, close) => {
        function onSelected(e) {
          let mergeField = e.target.value;
          if (mergeField) {
            editor.selection.insertNode(
              editor.create.inside.fromHTML("{{" + mergeField + "}}")
            );
          }
        }
        let divElement = editor.create.div("merge-field-popup");
  
        let labelElement = document.createElement("label");
        labelElement.setAttribute("class", "merge-field-label");
        labelElement.text = 'Merge field: ';
        divElement.appendChild(labelElement);
  
        let selectElement = document.createElement("select");
        selectElement.setAttribute("class", "merge-field-select");
        selectElement.appendChild(createOptionGroupElement(facilityMergeFields, "Facility"));
        selectElement.appendChild(createOptionGroupElement(inspectionMergeFields, "Inspection"));
        selectElement.onchange = onSelected;
        divElement.appendChild(selectElement);
  
      //  console.log(divElement);
        return divElement;
      }
    },
    {
      name: "copyContent",
      tooltip: "Copy HTML to Clipboard",
      iconURL: "images/copy.png",
      exec: function(editor) {
        let html = editor.value;
        copyStringToClipboard(html);
      }
    }
  ];

  const editorConfig = {
    readonly: false,
    toolbar: true,
    spellcheck: true,
    language: "pt_br",
    toolbarButtonSize: "medium",
    toolbarAdaptive: false,
    showCharsCounter: true,
    showWordsCounter: true,
    showXPathInStatusbar: false,
    askBeforePasteHTML: true,
    askBeforePasteFromWord: true,
    //defaultActionOnPaste: "insert_clear_html",
    buttons: buttons,
    uploader: {
      insertImageAsBase64URI: true
    },
    width: 800,
    height: 200
  };


  let Id =0;
  const initialState = {
    aviso: {
      id: 0,
      titulo: '',
      messagem: '',
      empresa_Id: empresa_Id,
      grupo_Id: 0,
      login_Id: Id,
      data: format(Date.now(),"yyyy-MM-dd'T'HH:mm:ss")
    }
  }

export default function CadastroAviso() {

 const [aviso,setAviso] = useState(initialState.aviso);
 const [data, setData]=useState('');
 const [destinatarios,setDestinatarios] =useState([]);
 const [tipoDestinatario,setTipoDestinatario] = useState('');
 const [bloco,setBloco]=useState(0);
 const history = useHistory();

 function handleOptionChange(e) {
  setTipoDestinatario( e.target.value);
  const valor = e.target.value;
  if(valor === 'G'){
    buscaGrupo();
  }
 
  if(valor === 'B'){
    buscaBloco();
     }
}


 async function VerificaUsuario(){
  setAviso({...aviso,empresa_Id: empresa_Id,login_Id: login_Id})    
}

 async function buscaGrupo() {
   let dest =await get('/Grupo/v1/Empresa/'+empresa_Id)
   if(dest !== undefined) {
    dest.unshift({id: 0, nome: '', flg_Todos: false})
      setDestinatarios(dest);
   }
 }

 async function buscaBloco() {
  let dest =await get('/Bloco/v1/Empresa/'+empresa_Id)
  if(dest !== undefined) {
    dest.unshift({id: 0, nome: '', empresa_Id: empresa_Id})
     setDestinatarios(dest);
  }
}

useEffect(()=> {
  VerificaUsuario();
},[])

useEffect(()=> {
  if(tipoDestinatario === 'G'){
  buscaGrupo();
  }

  if(tipoDestinatario === 'B'){
    buscaBloco();
  }
},[destinatarios])

function renderSelectDestinatario(){    
  if(destinatarios !== undefined){
      return destinatarios.map(destinatario =>{
          return (<option key={destinatario.id} value={destinatario.id}>{destinatario.nome}</option>)
      })
  }        
}

function trocaDestinatario(e){
  let avi = {...aviso};
  if(tipoDestinatario === 'G'){
    avi[e.target.name] =parseInt(e.target.value);
    let dest = destinatarios.filter(f => f.id === avi[e.target.name]);
    avi.flg_Todos = dest[0].flg_Todos;
  }

  if(tipoDestinatario === 'B'){
     avi[e.target.name] =parseInt(e.target.value);
     setBloco(avi[e.target.name]);
  }
  
  setAviso({...avi})
}

   async function save() {
    //console.log(JSON.stringify(aviso))
    let avi = await post('/Aviso/v1', aviso);
    if(tipoDestinatario === 'G'){
      avi.flg_Todos = aviso.flg_Todos;
      await post('/AvisoPessoa/v1/Grupo',avi)
    }

    if(tipoDestinatario === 'B'){
        avi.bloco_Id =bloco;

      await post('/AvisoPessoa/v1/Bloco',avi);

    }
  
    toast.success('Messagem enviada com sucesso')

    history.push('/Avisos')
    }

    function updateField(e){
      let avi = {...aviso};
      avi[e.target.name] =e.target.value;
      setAviso({...avi})
  }

    function renderForm(){
        return (
            <div className="cadAviso">
                <div className="field">
                  <label><i><strong>Assunto: </strong></i></label>
                  <input type="text" value={aviso.titulo} name="titulo" className="form-control assunto"
                  onChange={(e) =>{updateField(e)}} />
                </div>
        
                <div className="radio">
                <label>Tipo Destinatário:</label>
                              <label for="Grupo">
                              <input type="radio" id="grupo" value="G"  onChange={handleOptionChange}
                                   checked={ tipoDestinatario === 'G' }/>Grupo</label>                                

                              <label for="Bloco" >
                                   <input type="radio" id="bloco"  name="bloco" value="B"   onChange={handleOptionChange}
                                    checked={ tipoDestinatario === 'B' }/>Bloco</label>
    

                         </div>
                <div  className="field">
                  <label className="lblDestinatario"><i><strong>Destinatário: </strong></i></label>
                  <select name="grupo_Id" className="form-control destinatario"
                  value={aviso.grupo_Id}
                  onChange={(e) => {trocaDestinatario(e)}}>
                    {renderSelectDestinatario()}
                  </select>
                </div>

                <div className="editorCadastro">
                  <JoditEditor
                    value={data}
                    config={editorConfig}
                    onChange={value => {
                      setData(value); 
                      setAviso({...aviso,messagem: value});
                      ;}}

                  />
                </div>
                <hr />

                <div className="btnSalvar">
                
                  <button className="btn btn-primary" onClick={()=> save()}>Salvar</button>
                  <button className="btn btn-warning ml-2" onClick={()=> history.push('/Aviso')}>Cancelar</button>
                </div>
            </div>
        );
    }

    return (
        <Main {...headerProps}>
            {renderForm()}
        </Main>
    );
}