import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom';
import { isAuthenticated, isAdmin } from '../store/config/auth'
import {setLocalStorage,getLocalStorage} from '../store/config/storage'
import Visitante from '../pages/Visitantes/Visitante';
import VisitanteUsuario from '../pages/Visitantes/VisitanteUsuario';
import Auth from '../pages/Auth/Auth';
import  Condominio  from '../pages/Condominio/Condominio';
import Autorizacao from '../pages/Autorizacao/Autorizacao';
import TabAutorizacao from '../pages/Autorizacao/TabAutorizacao';
import CadastrarUser from '../pages/Auth/CadastrarUser';
import ConfirmacaoEmail from '../pages/Autorizacao/ConfirmacaoEmail';
import TestCrypto from '../components/Teste/Teste';
import AgendaVisitante from '../pages/Visitantes/AgendaVisitante';
import QrCodeLeitura from '../pages/Qrcode/QrcodeLeitura';
import EsqueciSenha from '../pages/Auth/EsqueciSenha';
import NovaSenha from '../pages/Auth/NovaSenha';
import CadastroEspaco from '../pages/Espaco/CadastroEspaco';
import NovoEspaco from '../pages/Espaco/NovoEspaco';
import Documentos from '../pages/Documentos/Documentos';
import Menu from '../components/template/Menu'
import Reservas from '../pages/Reservas';
import ReservaEspaco from '../pages/ReservaEspaco'
import Editor from '../pages/Editor/Editor'
import Regras from '../pages/Regras/Regras';
import ReservaConvidado from '../pages/Reserva/ReservaConvidados';
import Avisos from '../pages/Avisos/Avisos';
import CadastroAviso from '../pages/Avisos/CadastroAviso';
import CadastroGrupo from '../pages/Avisos/CadastroGrupo';
import VisualizarAviso from '../pages/Avisos/VisualizarAviso';
import AvisosMorador from '../pages/Avisos/AvisosMorador';
import AutorizaReserva from '../pages/Reserva/AutorizaReserva';
import Ocorrencia from '../pages/Ocorrencia/Ocorrencia';
import OcorrenciaCadastro from '../pages/Ocorrencia/OcorrenciaCadastro';
import HorariosAgendamentos from '../pages/Reserva/HorariosAgendamento';
import Hospede from '../pages/Hospede/Hospede';
import Veiculo from '../pages/Hospede/Veiculo';
import PesquisaHospede from '../pages/Hospede/PesquisaHospede';
import HospedeCadastrado from '../pages/Hospede/HospedeCadastrado';
import Acompanhante from '../pages/Hospede/Acompanhante';
import MoradoresAgendadosDia from '../pages/Reserva/MoradoresAgendadosDia';
import { buscaItemSessao } from '../util/Sessao/BuscarItemSessao';
import ReservaAgenda from '../pages/ReservaAgenda';
import ReservaAgendaDia from '../pages/ReservaAgendaDia';
import ReservaAgendado from '../pages/ReservaAgendado';
import MinhasReservas from '../pages/Reserva/MinhasReservas';
import ReservasCanceladasMulta from '../pages/Reserva/ReservasCanceladaMulta';
import Atividade from '../pages/Ocorrencia/Atividade';
import Tarefa from '../pages/Tarefa/Tarefa';
import TarefaFoto from '../pages/Tarefa/TarefaFoto';
import TarefaDocumento from '../pages/Tarefa/TarefaDocumento';
import TarefaComentario from '../pages/Tarefa/TarefaComentario';
import AtividadesResponsavel from '../pages/Tarefa/AtividadesResponsavel';

let result = buscaItemSessao();
let empresa='';

if(result !== undefined){
    empresa =result;
}


//553246736447566b58313837484f2b37476437506c67542f43634578487a68673535697042556b44705a553d

const PrivateRoute = ({component: Component,...rest}) => (

    

    <Route {...rest} render={props =>
    isAuthenticated()  ? (
        isAdmin() === true ? 
        (<Redirect to={{ pathname: '/Menu', state:{from: props.location}}}/>) 
        :
        (<Component {...props}/>) 
        
    ) 
    :
    (
        <Redirect to={{ pathname: '/Auth', state:{from: props.location}}}/>
      
    )
   
    }/>
    
    );

    const AdminRoute = ({component: Component,...rest}) => (
        <Route {...rest} render={props =>
        isAuthenticated()  ? (
            isAdmin() === false ? 
            (<Redirect to={{ pathname: '/Menu', state:{from: props.location}}}/>) 
            :
            (<Component {...props}/>) 
            
        ) 
        :
        (
            <Redirect to={{ pathname: '/Auth', state:{from: props.location}}}/>
          
        )
       
        }/>
        
        );
    
    const OpenRoute = ({component: Component,...rest}) => (
        <Route {...rest} render={props =>
        isAuthenticated() === false ? (
            <Component {...props}/>
        ) 
        :
        (
//            <Redirect to={{ pathname: '/Visitantes', state:{from: props.location }}} />
            <Redirect to={{ pathname: '/Menu', state:{from: props.location }}} />
        )
        
        }/>
        );

        const FreeRoute = ({component: Component,...rest}) => (
            <Route {...rest} render={props =>
            isAuthenticated() === false ? (
                <Component {...props}/>
            ) 
            :
            (
//                <Redirect to={{ pathname: '/Visitantes', state:{from: props.location }}} />
                <Redirect to={{ pathname: '/Visitantes', state:{from: props.location }}} />
)
            
            }/>
            );

export default function Routes () {
    return (
        <Switch>
            <PrivateRoute exact path="/Visitante"  component={ Visitante}/>
            <PrivateRoute exact path="/Visitante/:id" component={ Visitante}/>
            <PrivateRoute exact path="/Visitantes" component={ VisitanteUsuario}/>
            <PrivateRoute exact path="/Visitante/Agenda/:id" component={ AgendaVisitante}/>
            {/* <PrivateRoute path="/Reservas" component={Reservas} />
            <PrivateRoute exact path="/ReservaEspaco/:id" component={ReservaEspaco} />
            <PrivateRoute path="/ReservaEspaco/Agenda/:id/:data" component={ReservaAgenda} />
            <PrivateRoute path="/ReservaEspaco/AgendaDia/:id/:data/:horario" component={ReservaAgendaDia} />
            <PrivateRoute path="/ReservaEspaco/Agendado/:id/:idAgenda" component={ReservaAgendado} />
            <PrivateRoute exact path="/ReservaEspaco/Horario/:id/:data" component={HorariosAgendamentos} />*/}
            <PrivateRoute exact path="/Regras" component={Regras} />
               {/*<PrivateRoute exact path="/ReservaEspaco/Convidado/:idAgenda" component={ReservaConvidado} />
            <PrivateRoute exact path="/Hospedes/Pesquisa/:IdVisita" component={PesquisaHospede} />
            <PrivateRoute exact path="/Hospedes/Pesquisa" component={PesquisaHospede} />
            <PrivateRoute exact path="/Hospedes" component={HospedeCadastrado} />
            <PrivateRoute exact path="/Acompanhante/:idVisitaPrincipal" component={Acompanhante} />
            <PrivateRoute exact path="/Acompanhante/:idVisitaPrincipal/:idVisita" component={Acompanhante} />
            <PrivateRoute exact path="/MinhasReservas" component={MinhasReservas} /> */}
            <PrivateRoute exact path="/Piscina" component={() => {
                window.location.href = 'https://condominio.cisuporte.com.br/regimento/lavi_piscinas_saunas.html';
                return null;
                    }}/>
                    <PrivateRoute exact path="/Academia" component={() => {
                window.location.href = 'https://condominio.cisuporte.com.br/regimento/lavi_academia.html';
                return null;
                    }}/>
                    <PrivateRoute exact path="/Churrasqueira" component={() => {
                window.location.href = 'https://condominio.cisuporte.com.br/regimento/lavi_churrasqueira.html';
                return null;
                    }}/>
            <OpenRoute exact path="/Condominio" component={ Condominio}/>
            <OpenRoute  path="/Auth" component={Auth}/>
            <OpenRoute exact path="/Autorizacao" component={ Autorizacao}/>
            <OpenRoute exact path="/Autorizacao/:nome" component={ Autorizacao}/>
            <AdminRoute exact path="/Autorizados" component={ TabAutorizacao}/>
            <AdminRoute exact path="/Editor" component={ Editor}/>
            <OpenRoute exact path="/Cadastrar" component={CadastrarUser} />
            <OpenRoute exact path="/Confirmar/:dados" component={ConfirmacaoEmail} />
            <OpenRoute path="/Confirmar" component={ConfirmacaoEmail} />
            <Route exact path="/QRCode/:qrcode" component={QrCodeLeitura} />
            {/* <OpenRoute exact path="/Teste" component={ TestCrypto}/> */}
            <OpenRoute exact path="/Usuario/EsqueciSenha" component={EsqueciSenha} />
            <AdminRoute exact path="/CadastroEspaco" component={CadastroEspaco}/>
            <AdminRoute exact path="/Reserva/Autoriza" component={AutorizaReserva}/>
            <AdminRoute exact path="/Reserva/Cancelada" component={ReservasCanceladasMulta}/>
            <AdminRoute exact path="/Reserva/Agendamento" component={MoradoresAgendadosDia}/>
            <AdminRoute exact path="/NovoEspaco" component={NovoEspaco}/>
            <AdminRoute exact path="/NovoEspaco/:id" component={NovoEspaco}/>
            {isAdmin() === true ? <AdminRoute exact path="/Menu" component={Menu}/> :
              <PrivateRoute exact path="/Menu" component={Menu}/>}
           
           {/* {isAdmin() === true ? <AdminRoute exact path="/Avisos" component={Avisos}/> :
              <PrivateRoute exact path="/Avisos" component={AvisosMorador}/>} */}

            <OpenRoute exact path="/Usuario/NovaSenha/:id" component={NovaSenha} />
            <AdminRoute exact path="/Documentos" component={Documentos}/>
            <AdminRoute exact path="/Avisos/Cadastro" component={ CadastroAviso}/>
            <AdminRoute exact path="/Grupo/Cadastro" component={ CadastroGrupo}/>
            <AdminRoute exact path="/Grupo/Cadastro/:id" component={ CadastroGrupo}/>
            {/* <PrivateRoute exact path="/Ocorrencia" component={Ocorrencia}/>
            <PrivateRoute exact path="/Hospede/:tab" component={Hospede}/>
            <PrivateRoute exact path="/Hospede/:tab/:id" component={Hospede}/>
            <PrivateRoute exact path="/Ocorrencia/Cadastro" component={Atividade}/>
            <PrivateRoute exact path="/Ocorrencia/Cadastro/:id" component={Atividade}/>
            <PrivateRoute exact path="/Ocorrencia/:id/Tarefa" component={Tarefa}/>
            <PrivateRoute exact path="/Ocorrencia/:id/Tarefa/:idTarefa" component={Tarefa}/>
            <PrivateRoute exact path="/Ocorrencia/:id/Tarefa/:idTarefa/Foto" component={TarefaFoto}/>
            <PrivateRoute exact path="/Ocorrencia/:id/Tarefa/:idTarefa/Foto/:idFoto" component={TarefaFoto}/>
            <PrivateRoute exact path="/Ocorrencia/:id/Tarefa/:idTarefa/Documento" component={TarefaDocumento}/>
            <PrivateRoute exact path="/Ocorrencia/:id/Tarefa/:idTarefa/Documento/:idDocumento" component={TarefaDocumento}/>
            <PrivateRoute exact path="/Ocorrencia/:id/Tarefa/:idTarefa/Comentario" component={TarefaComentario}/>
            <PrivateRoute exact path="/Ocorrencia/:id/Tarefa/:idTarefa/Comentario/:idComentario" component={TarefaComentario}/>
            <PrivateRoute exact path="/Ocorrencia/Responsavel" component={AtividadesResponsavel}/> */}
            {/* {isAdmin() === true ? <AdminRoute exact path="/Avisos/Cadastro/:id" component={VisualizarAviso}/> :
              <PrivateRoute exact path="/Avisos/Cadastro/:id" component={VisualizarAviso}/>} */}
            {empresa == undefined || empresa == 'U2FsdGVkX1+Zfkg/76J4lB8/YS6LUUylHHo4FpHY0K4=' ?
             <Redirect from='*' to="/Auth" /> :
             <Redirect from='*' to="/Auth" />
        }
           
        </Switch>
    );
} 
    