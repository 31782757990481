import React,{useState} from "react";
import {FiX} from 'react-icons/fi';
import {post} from '../../store/config/register';
import { toast } from 'react-toastify';

const initialState={
 diaSemana: {
   dom: false,
   seg: false,
   ter: false,
   qua: false,
   qui: false,
   sex: false,
   sab: false
  }
}


export default function ModalReplicacao({conteudo,close}) {
    const [item,setItem]=useState(conteudo)
    const [diasSemana,setDiasSemana] = useState(initialState.diaSemana);
    const dias = [{id: 1,nome:"Domingo"}, {id: 2,nome:"Segunda"}, {id: 3,nome:"Terça"}, {id: 4,nome:"Quarta"}, {id: 5,nome:"Quinta"}, {id: 6,nome:"Sexta"}, {id: 7,nome:"Sabado"}]
    const [diasHorario,setDiasHorario]= useState([]);

    async function Replicar(dia){

        let dias =Object.keys(diasSemana);

        for(const Item of dias) {
            let d = diasSemana[Item];

            if(d) {
                let id = retornaDia(Item);

                let data ={espaco_id: parseInt(item.espaco_Id),origem_id: dia,destino_id: id}
                await post('/EspacoPeriodoHora/v1/Replicacao',data)
            }
        }
        toast.success("Horário Replicado");
        close();
    }

    function retornaDia(dia){

        let id =0;
        switch(dia){
          case 'dom':
            id=1;
            break;
          case 'seg':
            id=2;
            break;
          case 'ter':
            id=3;
            break;
          case 'qua':
            id=4;
            break;
          case 'qui':
            id=5;
            break;
          case 'sex':
            id=6;
            break;
          case 'sab':
            id=7;
            break;
        }
    
        return id;
    
       }

    function checkeddias(e){
        let dia={...diasSemana};
        dia[e.target.name]=e.target.value ==='true'? false : true;
        setDiasSemana({...dia})
      }

    return (
        <div className="modal3">
            <div className="container">
                <button className="closeAgenda" onClick={ close }>
                    <FiX size={23} color="#FFF" />
                    Voltar
                </button>
                
                <div className="checkbox">
                  {dias.map((item,index)=>{
                      let nome = item.nome.toLowerCase().substring(0,3)
                    return (
                   <div className="checkbox">
                  <input
                    type="checkbox"
                    id={`custom-checkbox-${index}`}
                    name={nome}
                    value={diasSemana[nome]}
                    checked={diasSemana[nome] === true}
                    onChange={(e) => checkeddias(e)}
                  />
                <div className="right-section">{ nome}</div>
                </div>
                    );
                  })}
                </div>
                <div>
                    <br />
                <button className="btn btn-success ml-2"  onClick={async()=>await Replicar(item.dia_Semana)}>Replicar</button>
                </div>
                
            </div>
        </div>
    )
}