import React,{useState,useEffect} from "react";
import Main from "../../components/template/Main";
import {get,del,post, put} from '../../store/config/register';
import { format,parse } from "date-fns";
import { empresa_Id } from "../../store/config/dadosuser";
import { urgenciasLista } from "../../util/Urgencia";
import { useHistory,useParams } from "react-router-dom";
import { FiEdit2,FiX } from "react-icons/fi";
import { handleKeyDown } from "../../util/Input";

import './Atividade.css';
import { toast } from "react-toastify";

const initialState ={
    ocorrencia: {
      id: 0,
      titulo: '',
      descricao: '',
      idCategoria: 0,
      data_Criacao: new Date(),
      data_Prevista: '',
      idResponsavel: 0,
      idPrioridade: 0,
      idLocal: 0,
      tipoAtividade: 1,
      repeticao: false,
      tipoRepeticao: 0,
      status: 0,
      empresa_Id: empresa_Id
    }
  }

  const headerProps = {
    icon: "file-text-o",
    title: "Atividade",
    subtitle: "Cadastro"
}



export default function Atividade(){
  const {id} = useParams();
  const [ocorrencia,setOcorrencia] = useState(initialState.ocorrencia);
  const [categorias,setCategorias] = useState([]);
  const [responsaveis,setResponsaveis] = useState([]);
  const [recorrencias,setRecorrencias] = useState([]);
  const [locais,setLocais] = useState([]);
  const [urgencias,setUrgencias] = useState(urgenciasLista);
  const [tarefas, setTarefas] = useState([]);
  const [documentos, setDocumentos] = useState([]);
  const [comentarios, setComentarios] = useState([]);
  const [fotos, setFotos] = useState([]);
  const history = useHistory();

  async function buscaRepeticao() {
    const resp = await get('/TipoRepeticao/v1');
    if(resp.length > 0){
      resp.unshift({id: 0, nome: ''})
        setRecorrencias(resp);
    }
  }

  async function buscaEspaco() {
    const espa = await get(`/Espaco/v1/Empresa/${empresa_Id}`);
    if(espa.length > 0){
       espa.unshift ({
      id: 0,
      nome: "",
      anexo_Pagamento: false,
      antecedencia_Maxima: 0, 
      antecedencia_Minima: 0, 
      capacidade: 0, 
      desistencia: 0,
      empresa_Id:0, 
      flg_Controle_Periodo: null, 
      flg_Descricao_Evento: false, 
      flg_Numero_Convidado: false, 
      flg_Taxa_Reserva: false, 
      lista_Convidado: false, 
      taxa: 0, 
      termo: false, 
      tipo_Espaco_Id:0
    })
        setLocais(espa);
    }
  }

  async function buscaResponsavel() {
    const reps = await get(`/Responsavel/v1/Empresa/${empresa_Id}`);
    if(reps.length > 0){
      reps.unshift ({empresa_Id: 0, id_Replicacao: 0, nome: ''})
        setResponsaveis(reps);
    }
  }

  async function buscaCategoria() {
    const cate = await get(`/Categoria/v1/Empresa/${empresa_Id}`);
    if(cate.length > 0){
      cate.unshift ({ id: 0, nome: '', empresa_Id: 0});
        setCategorias(cate);
    }
  }

  async function buscaTarefas(){
    const tare = await get(`/Tarefa/v1/Atividade/${id}`);
    if(tare !== undefined){
        setTarefas(tare)
    }
  }

  async function buscaDocumentos(){
    const docu = await get(`/ArquivoAtividade/v1/Atividade?IdAtividade=${id}&IdTarefa=0`);
    if(docu !== undefined){
        setDocumentos(docu)
    }
  }

  async function buscaFotos(){
    const foto = await get(`/FotoAtividade/v1/Tarefa?IdAtividade=${id}&idTarefa=0`);
    if(foto !== undefined){
        setFotos(foto)
    }
  }

  async function buscaComentarios(){
    const come = await get(`/ComentarioAtividade/v1/Atividade/${id}`);
    if(come !== undefined){
        setComentarios(come)
    }
  }

  async function buscaAtividade() {
   const ativi =  await get(`/Atividade/v1/${id}`);
    if(ativi !== null){
      setOcorrencia(ativi);
    }

  }

  useEffect(()=> {
    buscaRepeticao();
    buscaResponsavel();
    buscaEspaco();
    buscaCategoria();

    if(id !== undefined) {
      buscaAtividade();
      buscaTarefas();
      buscaDocumentos();
      buscaFotos();
      buscaComentarios();
    }
  },[])

  function  updateField( event){
    const ocor ={...ocorrencia}
    ocor[event.target.name] = event.target.value
    setOcorrencia(ocor);
}

function trocaCategoria(e) {
    setOcorrencia({...ocorrencia, idCategoria: parseInt(e.target.value)});
}


function trocaRecorrencia(e) {
    setOcorrencia({...ocorrencia, tipoRepeticao: parseInt(e.target.value)});
}

function trocaResponsavel(e) {
    setOcorrencia({...ocorrencia, idResponsavel: parseInt(e.target.value)});
}

function trocaLocal(e) {
    setOcorrencia({...ocorrencia, idLocal: parseInt(e.target.value)});
}

function trocaUrgencia(e) {
    setOcorrencia({...ocorrencia, idPrioridade: parseInt(e.target.value)});
}

function renderSelectCategoria(){    
    if(categorias !== undefined){
        return categorias.map(categoria =>{
            return (<option key={categoria.id} value={categoria.id}>{categoria.nome}</option>)
        })
    }        
}

function renderSelectResponsavel(){    
    if(responsaveis !== undefined){
        return responsaveis.map(responsavel =>{
            return (<option key={responsavel.id_Replicacao} value={responsavel.id_Replicacao}>{responsavel.nome}</option>)
        })
    }        
}

function renderSelectRecorrencia(){    
    if(recorrencias !== undefined){
        return recorrencias.map(recorrencia =>{
            return (<option key={recorrencia.id} value={recorrencia.id}>{recorrencia.nome}</option>)
        })
    }        
}


function renderSelectLocal(){    
    if(locais !== undefined){
        return locais.map(local =>{
            return (<option key={local.id} value={local.id}>{local.nome}</option>)
        })
    }        
}

function renderSelectUrgencia(){    
    if(urgencias !== undefined){
        return urgencias.map(urgencia =>{
            return (<option key={urgencia.id} value={urgencia.id}>{urgencia.nome}</option>)
        })
    }    
        
}

function EditTarefa( idTarefa){
 history.push(`/Ocorrencia/${id}/Tarefa/${idTarefa}`)
}

function EditDocumento( idDocumento){
    history.push(`/Ocorrencia/${id}/Tarefa/0/Documento/${idDocumento}`)
   }

   function EditFoto( idFoto){
    history.push(`/Ocorrencia/${id}/Tarefa/0/Foto/${idFoto}`)
   }

   function EditComentario( idComentario){
    history.push(`/Ocorrencia/${id}/Tarefa/0/Comentario/${idComentario}`)
   }

async function DeleteTarefa(idTarefa){
 await del(`/Tarefa/v1?Id=${idTarefa}&IdAtividade=${id}`)
 toast.success('Tarefa apagada com sucesso');
 buscaTarefas();
}

async function DeleteDocumento(idDocumento){
    await del(`/ArquivoAtividade/v1?Id=${idDocumento}&IdTarefa=0&IdAtividade=${id}`)
    toast.success('Documento removido com sucesso');
    buscaDocumentos();
   }

   async function DeleteFoto(idFoto){
    await del(`/FotoAtividade/v1?Id=${idFoto}&IdTarefa=0&IdAtividade=${id}`)
    toast.success('Foto removida com sucesso');
    buscaFotos();
   }

   async function DeleteComentario(idComentario){
    await del(`/ComentarioAtividade/v1?IdAtividade=${id}&IdTarefa=0&Id=${idComentario}`)
    toast.success('comentario removido com sucesso');
    buscaComentarios();
   }

   async function Salvar() {
    ocorrencia.data_Criacao = format(new Date(),"yyyy-MM-dd'T'HH:mm:ss");
  
    if(id === undefined){
      const ativ = await post('/Atividade/v1', ocorrencia);
      history.push(`/Ocorrencia/Cadastro/${ativ.id}`);
      toast.success("Atividade Salvo com sucesso");
    }else {
      const ativ = await put(`/Atividade/v1/${id}`, ocorrencia);
      history.push(`/Ocorrencia/Cadastro/${ativ.id}`);
      toast.success("Atividade Salvo com sucesso");
    }

   }

   function checkedRepeticao(e){
    let ocor={...ocorrencia};
    ocor[e.target.name]=e.target.value ==='true'? false : true;
    setOcorrencia(ocor);
  }

    function renderForm(){

        return (
            <div className="form">
                <div className="email">
                    <div className="form-group">
                    <label>Título:</label>
                    <input type="text" name="titulo" 
                    placeholder="Titulo"
                        className="form-control" value={ocorrencia.titulo} 
                        onChange={e => updateField(e)} />
                    </div>
                </div>
                <div className="email">
                    <div className="form-group">
                    <label>Descrição:</label>
                    <textarea  rows="1" type="text" name="descricao" placeholder="Descrição" onKeyDown={handleKeyDown}
                        className="form-control auto_height" value={ocorrencia.descricao} 
                        onChange={e => updateField(e)} />
                    </div>
                </div>
                <div className="nome-visitante">
                    <div className="form-group">
                    <label>Categoria:</label>
                    <select  name="idCategoria" 
                        className="form-control"  
                        value={ocorrencia.idCategoria}  
                        onChange={trocaCategoria}>{renderSelectCategoria()} </select>
                    </div>
                </div>
                <div className="email">
                    <div className="form-group">
                    <label>Data Prevista:</label>
                    <input type="datetime-local" name="data_Prevista" 
                    placeholder="Descrição"
                        className="form-control" value={ocorrencia.data_Prevista} 
                        onChange={e => updateField(e)} />
                    </div>
                </div>
                <div className="nome-visitante">
                    <div className="form-group">
                    <label>Responsável:</label>
                    <select  name="idResponsavel" 
                        className="form-control"  
                        value={ocorrencia.idResponsavel}  
                        onChange={trocaResponsavel}>{renderSelectResponsavel()} </select>
                    </div>
                </div>
                <div className="arquivo">
                    <label>Foto:</label>
                    <button className="btn btn-primary" onClick={ async e => history.push(`/Ocorrencia/${id}/Tarefa/0/Foto`)}>Novo</button>
                </div>
                {renderTableFotos()}
                <div className="arquivo">
                    <label>Documento:</label>
                    <button className="btn btn-primary" onClick={ async e => history.push(`/Ocorrencia/${id}/Tarefa/0/Documento`)}>Novo</button>
                </div>
                {renderTableDocumentos()}
                <hr />
                <div className="arquivo">
                    <label>Comentário:</label>
                    <button className="btn btn-primary" onClick={ async e => history.push(`/Ocorrencia/${id}/Tarefa/0/Comentario`)}>Novo</button>
                </div>
                {renderTableComentarios()}

                <div className="checkbox">
                  <input
                    type="checkbox"
                    id={0}
                    name="repeticao"
                    value={ocorrencia.repeticao}
                    checked={ocorrencia.repeticao === true}
                    onChange={(e) => checkedRepeticao(e)}
                  />
                <div className="right-section">Repetição</div>
                </div>
                {ocorrencia.repeticao && (
                  <div className="nome-visitante">
                  <div className="form-group">
                  <label>Essa atividade se repete:</label>
                  <select  name="tipoRepeticao" 
                      className="form-control"  
                      value={ocorrencia.tipoRepeticao}  
                      onChange={trocaRecorrencia}>{renderSelectRecorrencia()} </select>
                  </div>
              </div>
                )}
                
                <div className="nome-visitante">
                    <div className="form-group">
                    <label>Local:</label>
                    <select  name="idLocal" 
                        className="form-control"  
                        value={ocorrencia.idLocal}  
                        onChange={trocaLocal}>{renderSelectLocal()} </select>
                    </div>
                </div>
                <div className="nome-visitante">
                    <div className="form-group">
                    <label>Prioridade:</label>
                    <select  name="idPrioridade" 
                        className="form-control"  
                        value={ocorrencia.idPrioridade}  
                        onChange={trocaUrgencia}>{renderSelectUrgencia()} </select>
                    </div>
                </div>
                <div className="arquivo">
                    <label>Tarefa:</label>
                    <button className="btn btn-primary" onClick={ async e => history.push('/Ocorrencia/1/Tarefa')}>Novo</button>
                </div>
                {renderTableTarefa()}
                <hr />

                <div id="botao">
                  <button  className="btn btn-primary ml-2" onClick={ async e => await Salvar()}>
                    Salvar
                  </button>
                  <button className="btn btn-secondary ml-2" onClick={e =>history.goBack()}>
                    Cancelar
                  </button>
            </div>       
            </div>
        );
    }


    function renderTableTarefa() {
        return (
           <div className='tabela'>
                           <table>
                             <thead>
                               <tr>
                                 <th scope="col" >Tarefa</th>
                                 <th scope="col"></th>
                               </tr>
                             </thead>
           
                             <tbody>
                               {tarefas === undefined ? null : tarefas.map((tarefa,index) => {
                                 return (
                                   <tr key={index}>
                                       <td data-label="Tarefa">{tarefa.qFazer}</td>
                                       <td  data-label="">
                                       <button className="action" id="btn1" style={{backgroundColor: '#F99E08' ,marginRight: "10px" }} data-toggle="tooltip" data-placement="bottom" title="Editar" onClick={() => EditTarefa(tarefa.id)}>
                                         <FiEdit2 color="#FFF" size={17} />
                                         </button>
                                         <button className="action" style={{backgroundColor: '#ff0000',marginRight: "10px" ,marginTop: "10px" }} data-toggle="tooltip" data-placement="bottom" title="Excluir Espaço" onClick={()=> DeleteTarefa(tarefa.id)} >
                                         <FiX color="#FFF" size={17} />
                                         </button>
                                         </td>
                                     </tr>
                                 );
                               })}
                                     
           
           
                             </tbody>
                           </table>
           
                   </div>
        );
    }

    function renderTableDocumentos() {
        return (
           <div className='tabela'>
                           <table>
                             <thead>
                               <tr>
                                 <th scope="col" >Titulo</th>
                                 <th scope="col"></th>
                               </tr>
                             </thead>
           
                             <tbody>
                               {documentos === undefined ? null : documentos.map((documento,index) => {
                                 return (
                                   <tr key={index}>
                                       <td data-label="Titulo">{documento.titulo}</td>
                                       <td  data-label="">
                                       <button className="action" id="btn1" style={{backgroundColor: '#F99E08' ,marginRight: "10px" }} data-toggle="tooltip" data-placement="bottom" title="Editar" onClick={() => EditDocumento(documento.id)}>
                                         <FiEdit2 color="#FFF" size={17} />
                                         </button>
                                         <button className="action" style={{backgroundColor: '#ff0000',marginRight: "10px" ,marginTop: "10px" }} data-toggle="tooltip" data-placement="bottom" title="Excluir Espaço" onClick={()=> DeleteDocumento(documento.id)} >
                                         <FiX color="#FFF" size={17} />
                                         </button>
                                         </td>
                                     </tr>
                                 );
                               })}
                                     
           
           
                             </tbody>
                           </table>
           
                   </div>
        );
    }

    function renderTableFotos() {
        return (
           <div className='tabela'>
                           <table>
                             <thead>
                               <tr>
                                 <th scope="col" >Titulo</th>
                                 <th scope="col"></th>
                               </tr>
                             </thead>
           
                             <tbody>
                               {fotos === undefined ? null : fotos.map((foto,index) => {
                                 return (
                                   <tr key={index}>
                                       <td data-label="Titulo">{foto.titulo}</td>
                                       <td  data-label="">
                                       <button className="action" id="btn1" style={{backgroundColor: '#F99E08' ,marginRight: "10px" }} data-toggle="tooltip" data-placement="bottom" title="Editar" onClick={() => EditFoto(foto.id)}>
                                         <FiEdit2 color="#FFF" size={17} />
                                         </button>
                                         <button className="action" style={{backgroundColor: '#ff0000',marginRight: "10px" ,marginTop: "10px" }} data-toggle="tooltip" data-placement="bottom" title="Excluir Espaço" onClick={()=> DeleteFoto(foto.id)} >
                                         <FiX color="#FFF" size={17} />
                                         </button>
                                         </td>
                                     </tr>
                                 );
                               })}

                             </tbody>
                           </table>
           
                   </div>
        );
    }

    function renderTableComentarios() {
      return (
         <div className='tabela'>
                         <table>
                           <thead>
                             <tr>
                               <th scope="col" >Descrição</th>
                               <th scope="col"></th>
                             </tr>
                           </thead>
         
                           <tbody>
                             {comentarios === undefined ? null : comentarios.map((comentario,index) => {
                               return (
                                 <tr key={index}>
                                     <td data-label="Titulo">{comentario.descricao}</td>
                                     <td  data-label="">
                                     <button className="action" id="btn1" style={{backgroundColor: '#F99E08' ,marginRight: "10px" }} data-toggle="tooltip" data-placement="bottom" title="Editar" onClick={() => EditComentario(comentario.id)}>
                                       <FiEdit2 color="#FFF" size={17} />
                                       </button>
                                       <button className="action" style={{backgroundColor: '#ff0000',marginRight: "10px" ,marginTop: "10px" }} data-toggle="tooltip" data-placement="bottom" title="Excluir Espaço" onClick={()=> DeleteComentario(comentario.id)} >
                                       <FiX color="#FFF" size={17} />
                                       </button>
                                       </td>
                                   </tr>
                               );
                             })}

                           </tbody>
                         </table>
         
                 </div>
      );
  }

    return(
        <Main {...headerProps}>
            {renderForm()}
        </Main>
    );
}