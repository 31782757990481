import React,{useState} from "react";
import { FiX } from "react-icons/fi";
import { dataURLtoFile } from "../../util/Imagem";

export default function ModalImagem({conteudo, close}){
    const [item,setItem]=useState(conteudo);

  const imagem =  dataURLtoFile(`data:image/png;base64,${item.pagamento}`,'teste.png');

    return (
        <div>
        <div className="modal3">
            <div className="container">
                <button className="closeAgenda" onClick={ () => close(null,'img') }>
                    <FiX size={23} color="#FFF" />
                    Voltar
                </button>
                    <img src={URL.createObjectURL(imagem)} width="250" height="250" alt="Comprovante de pagamento"/>
            </div>
        </div>
    </div>
    );
}