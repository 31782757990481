import CryptoJS from "crypto-js";
import { secretKey } from "../../global";

export const Descriptografar = (dados)=> {
       let bytes  = CryptoJS.AES.decrypt(dados, secretKey).toString(CryptoJS.enc.Utf8);
       let decryptedData = bytes.toString(CryptoJS.enc.Utf8);
    
       return decryptedData;
}


export const  isJsonString=(str)=> {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}