import './Header.css'
import React from 'react'
import { useHistory } from "react-router-dom";
import CryptoJS from "crypto-js";
import Dropdown from 'react-bootstrap/Dropdown';
import { authKey,secretKey } from '../../global';
import { toast } from "react-toastify";
import { Buffer } from 'buffer';
import { isAuthenticated } from '../../store/config/auth';
import { empresa_Id } from '../../store/config/dadosuser';
import { user } from '../../store/config/dadosuser';

export default function Header(props){
  const history = useHistory();

  const convert = (from, to) => str => Buffer.from(str, from).toString(to)

  const utf8ToHex = convert('utf8', 'hex')

  function logout(){
    const empresa =Encrypt();
   localStorage.clear(authKey);
    history.push("/Auth?empresa="+empresa);
    window.location.reload();
  }

  function Encrypt(){
    let resp = CryptoJS.AES.encrypt(empresa_Id.toString(), secretKey).toString();
    return utf8ToHex(resp);
  }

  return (
          <header className="header d-none d-sm-flex flex-column">
            <div className="item">
              <h1 className="mt-3">
                  <i className={`fa fa-${props.icon}`}></i> {props.title}
              </h1>
            
              <p className="lead text-muted">{props.subtitle}</p>
              {isAuthenticated() ? (<Dropdown id="drop">
                  <Dropdown.Toggle variant="primary" id="dropdown-basic">
                  {user.nome}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href="/">Home</Dropdown.Item>
                    <Dropdown.Item onClick={()=>logout()}>Logout</Dropdown.Item>
                  </Dropdown.Menu>
              </Dropdown>) 
              : 
              null
              }

          </div>
              
        </header>

  );
}
