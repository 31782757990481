import React, {useState,useEffect} from "react";
import Main from "../../components/template/Main";
import { Buffer } from 'buffer';
import avatar from '../../assets/imgs/avatar.png';

import { secretKey} from "../../global";

import { post, put,get } from "../../store/config/register";
import { toast } from 'react-toastify';
import { empresa, empresa_Id } from "../../store/config/dadosuser";
import CryptoJS from "crypto-js";

const headerProps = {
    icon: "envelope",
    title: "Confirmação de Email",
  }

export default function ConfirmacaoEmail (props) {
    const [templateEmail,setTemplateEmail]= useState({});
    const [emailParam,setEmailParam]= useState({});

    const link =window.location.pathname.replace('/Confirmar/','')
    const dados =props.match.params.dados === undefined ? link :props.match.params.dados;
  ;
    
    let nome ='';

  async  function VerificaConfirmacao(){
     //   let bytes  = CryptoJS.AES.decrypt(dados, secretKey).toString(CryptoJS.enc.Utf8);
       // let decryptedData = bytes.toString(CryptoJS.enc.Utf8);
        let decryptedData = hexToUtf8(dados);
        let email =decryptedData.split('/')[0];
        let id =decryptedData.split('/')[1];
      const usu=  await Validar(email,id);

      nome = usu.nome;
     // console.log(usu);
      const unid= await get("/Unidade/v1/"+ usu.unidade_Id)
      usu.unidade = unid.nome;

        if(usu !== undefined){
         
         let temp = await BuscaTemplate(usu.empresa_Id)
            const data={confirmar: true};
        let param = await  BuscaEmailParam(usu.empresa_Id);
            await put('/Login/v1/AlteraConfirmacao/'+usu.id, data);

            await enviarEmail(usu,temp,param);

        }
    }

    const convert = (from, to) => str => Buffer.from(str, from).toString(to)
const utf8ToHex = convert('utf8', 'hex')
const hexToUtf8 = convert('hex', 'utf8')

    useEffect(()=>{
        if(dados !== undefined) {
            VerificaConfirmacao();
        }
    },[]);

    async function Validar(email,id){
        const data ={email: email, id: parseInt(id)};
     let usu = await post('/Login/v1/Ultimo', data);
     return usu;
    }

    async function BuscaEmailParam(idEmpresa) {
        let result = await get('/ParamEmailResp/v1/'+idEmpresa);
        setEmailParam(result);

        return result;
    }

    async function BuscaTemplate(idEmpresa){
        let result = await get('/Template/v1?tipo=3&empresa='+idEmpresa);

        setTemplateEmail(result);
        return result;
       }

    async function enviarEmail(usuario,templateEmail,param){

   
        let emails =[];
        emails.push(param.email); 
      // emails.push('william@cisistemas.com.br');
       templateEmail.template =templateEmail.template.replace('@nome',usuario.nome);
       templateEmail.template =templateEmail.template.replace('@unidade',usuario.unidade);

        let data =  { sender:emails, subject: templateEmail.assunto,html: templateEmail.template,empresa_id: usuario.empresa_Id}
         //console.log(JSON.stringify(data));
         let result =await post('/Email/v1',data);
         await toast.success('email enviado com sucesso');
      }

      function template(usuario){
        let temp=`<html>
        <head>
        <meta charset="utf-8">
        <meta name="viewport" content="width=device-width, initial-scale=1">
        <title>Troca de senha de usuário - Aplicativo do Condomínio - La Villete</title>
        </head>
		
         <body>
         <div bgcolor="#FFFFFF" marginwidth="0" marginheight="0">
			<center>
			
				<table width="640" border="0" cellpadding="0" cellspacing="0" align="center" bgcolor="#f5f5f5">
					<tr>
						<td>
							<table cellpadding="0" cellspacing="0" border="0" style="font-family:Calibri,sans-serif" width="100%">
							</table>

							<p>&nbsp;</p>
							
							 <table width="640" border="0" cellpadding="0" cellspacing="0" align="center" bgcolor="#f5f5f5">
								 <tr>
									 <td>
										 <table align="center" cellpadding="0" cellspacing="0" style="font-family:Calibri,sans-serif" width="720">
											 <tbody>
												 <tr>
													 <td style="padding:0px">
														 <center><a href="https://pt-br.facebook.com/condominiolavillette/" target="_blank" rel="noreferrer">
															 <img alt="Condomínio La Villette" src="https://condominio.cisuporte.com.br/imagens/logolavillete.png" width="50%" height="50%" 
															 style="display:block">
														 </a></center>
													 </td>
												 </tr>
											 </tbody>
										</table>
									</td>
								</tr>
										
								<tr>
									<td>								
										<h1 style="display:block;font-size:18px;color:#363636;margin:26px 15px;line-height:130%;font-family:Calibri,sans-serif;text-align:center;font-weight:400">
										 Olá Administração,</h1>
										 <h2 style="display:block;font-size:18px;color:#363636;margin:0px 25px 0px;line-height:130%;font-family:Calibri,sans-serif;text-align:center;text-transform:uppercase;font-weight:900">
											 AUTORIZAÇÃO PENDENTE PARA MORADOR UTILIZAR O APP
										 </h2>
										 <br />  
										 <h3 style="display:block;font-size:16px;color:#363636;margin:0px 25px 0px;line-height:130%;font-family:Calibri,sans-serif;text-align:center;text-transform:uppercase;font-weight:900">
										 ${usuario.nome}
										 </h3>
										 <h4 style="display:block;font-size:14px;color:#363636;margin:0px 25px 0px;line-height:130%;font-family:Calibri,sans-serif;text-align:center;text-transform:uppercase;font-weight:900">
										 ${usuario.unidade}
										 </h4>
										 <p style="display:block;font-size:16px;color:#363636;margin:22px 25px;line-height:130%;font-family:Calibri,sans-serif;text-align:center">
										 Acesse o sistema, vá no menu "Autorização". Em seguida verifique o nome do morador na aba de "Login Pedente" e faça a autorização.<br>
										</p>
									</td>
								</tr>
								
								<tr style="margin-top:25px">
									<td>
										<div style="margin-top:25px">
										</div>
									</td>
								</tr>
										
								<tr>
								<td>
									<a href="https://pt-br.facebook.com/condominiolavillette/" 
										target="_blank" rel="noreferrer">
										<img alt="La Villette"	src="https://condominio.cisuporte.com.br/imagens/rodape_emm_lavillette_640x96.png" width="100%" height="100%">
									</a>
								</td> 
								</tr>
							</table>
						</td>
					</tr>			
				</table>
					<p>
                      <a href="https://acessoid.com.br/" target="_blank" rel="noreferrer"> Enviado por www.acessoid.com.br </a>
                    </p>
			</center>
		</div>
	</body>
</html>`;
 
        return temp;
       }

    function render() {

        return (
            <div className="aviso">
                <img src={avatar} width={90} height={105} alt="Foto de perfil do usuario" />
                <div className="autorizacao">
                <label>{nome}</label>
                <label>Conta confirmada. Aguarde autorização para acessar o aplicativo.</label>
                </div>

            </div>
        );
    }
    return(
        <Main {...headerProps}>
        {render()}
    </Main>
    );
    
}