import React,{useState,useEffect} from "react";
import Main from "../../components/template/Main";
import ButtonImage from "../../components/template/ButtonImage";
import { empresa_Id } from "../../store/config/dadosuser";
import { get } from "../../store/config/register";

const headerProps = {
    icon: "tasks",
    title: "Regras de Espaço",
  }

export default function Regras(){
    const [links,setLinks]=useState([]);


    async function BuscaLinkEspaco(){

      const  link =await get ('/EspacoLink/v1/Empresa/'+1);
      setLinks(link);
    }

    useEffect(()=>{
        BuscaLinkEspaco();
    },[])


    function returnImage(tipo){
        if(tipo === 'piscina'){
            return require('../../assets/imgs/piscina_80x80.png');
        }else if(tipo === 'churrasqueira'){
            return  require('../../assets/imgs/churrasqueira_80x80.png');
        }else if(tipo === 'academia'){
            return  require('../../assets/imgs/esteira_80x80.png');
        }else if(tipo === 'tennis'){
            return require('../../assets/imgs/tenis-80x80.png');
        }else if(tipo === 'campo'){
            return require('../../assets/imgs/campo_80x80.png');
        }
    }

    function renderForm(){
        return (
            <div id="menu-div1">
                 <div className='menus form'>
                    {links !== undefined ? links.map ((item) =>{                   
                       
                       return (
                            <ButtonImage  conteudo={{nome: item.nome, color:item.color,icon: 'comments',link: item.link, image: returnImage(item.image_Link)}}/>
                        );
                    }
                      
                    ): null
                    }
                 </div>
            </div>
        );
    }
    return (
        <Main {...headerProps}>
            {renderForm()}
        </Main>
    );
}