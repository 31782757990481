import React,{useState,useEffect} from "react";
import Main from "../../components/template/Main";
import  DatePicker  from "react-mobile-datepicker";
import { empresa_Id } from "../../store/config/dadosuser";
import { get } from "../../store/config/register";
import useTable from "../../components/table/useTable";
import TableFooter from "../../components/table/TableFooter";
import { parse,format } from "date-fns";
import './MoradoresAgendadosDia.css'

const headerProps = {
    icon: 'comments',
    title: 'Agendamento de Reserva'
  }

  const initialState ={
    agendamento: {
        data: new Date(),
        espaco_id: 0

    }
  }

export default function MoradoresAgendadosDia(){

    const [agendamento,setAgendamento] = useState(initialState.agendamento);
    const [selectEspacos, setSelectEspacos] =useState([]);
    const [espacos,setEspacos] =useState([]);
    const [agendamentos,setAgendamentos] =useState([]);
    const [page, setPage] = useState(1);
    const { slice, range } = useTable(agendamentos, page, 10);
    const [isOpen,setIsOpen]= useState(false);




    function renderSelectEspaco(){    
        if(espacos !== undefined){
            return espacos.map(espaco =>{
                return (<option key={espaco.id} value={espaco.id}>{espaco.nome}</option>)
            })
        }        
    }

    const dateConfig = {
        'date': {
          format: 'DD',
          caption: 'Dia',
          step: 1,
      },
      'month': {
          format: 'MM',
          caption: 'Mês',
          step: 1,
      },
      'year': {
        format: 'YYYY',
        caption: 'Ano',
        step: 1,
    }
      
    };

    useEffect(()=> {
        BuscaEspacos();

    },[]);

    function handleCancel() {
        setIsOpen(false);
      }
      
      function handleSelect (time) {
        setIsOpen(false);
        setAgendamento({...agendamento, data:time})
      }

    async function BuscaEspacos(){
        const esps= await get(`/Espaco/v1/Empresa/${empresa_Id}`)
        if(esps.length> 0 ){
            setAgendamento({...agendamento,espaco_id: esps[0].id})
               setEspacos(esps)
           }
    }

  async  function BuscaAgendamentos(){
 
      const agend=  await get(`/EspacoAgenda/v1/Pesquisa?espaco_id=${agendamento.espaco_id}&inicio=${format(agendamento.data,'yyyy-MM-dd')}`);
      if(agend.length > 0){

        setAgendamentos(agend);
      }
    }

    function trocaEspaco(e) {
        setAgendamento({...agendamento, espaco_id: parseInt(e.target.value)})
      }

      function renderTable(){
        return (
            <table className="tabela3">
                <thead>
                    <tr>
                        <th scope="col">Unidade</th>
                        <th scope="col">Horário</th>
                    </tr>
                </thead>
                {  slice.length === 0 ? null : slice.map((item,index) => {
                                return( 
                                    <tbody>
                                        <tr key={index}>
                                            <td className="tabela3" data-label="Unidade">{item.unidade}</td>
                                            <td className="tabela3" data-label="Horário">{format(parse(item.data,"yyyy-MM-dd'T'HH:mm:ss",new Date()),'HH:mm')}</td>
                                        </tr>
                                    </tbody>
                                );
                                })}
            </table>
        );
      }

      function PesquisaAgendamento(){

      }

    function renderForm(){
        return (
            <div className="form">
                 <div className="nome-visitante">
                <div className="form-group">
                  <label>Espaço:</label>
                  <select type="text" name="espaco" 
                    className="form-control" value={agendamento.espaco_id}  
                    onChange={(e) => trocaEspaco(e)} >{renderSelectEspaco()} </select>
                </div>
            </div> 

            <div className="data0">
              <div className="form-group">
                    <label>Data:</label>
                     <input  name="data"  className="form-control" readOnly onClick={()=> {setIsOpen(true);}}
                    value={agendamento.data !== null ?format(agendamento.data,'dd/MM/yyyy') : ''}/> 
                        <DatePicker confirmText="Selecionar" cancelText="Cancelar" headerFormat="DD/MM/YYYY"className="form-control"  showCaption={true} dateConfig={dateConfig} 
                        value={agendamento.data === null ? new Date() : agendamento.data}
                        isOpen={isOpen}
                        onSelect={(time)=> handleSelect(time)}
                        onCancel={()=> handleCancel()}/>
              </div>
            </div>

                    <div className="row1">
                        <button className="btn btn-primary" onClick={e => {BuscaAgendamentos()}}>
                            Pesquisar
                        </button>
                  </div>

                  {renderTable()}
            </div>
            
        );
    }

    return(
        <Main {...headerProps}>
            {renderForm()}
        </Main>
    );
}