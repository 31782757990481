import 'bootstrap/dist/css/bootstrap.min.css'
import 'font-awesome/css/font-awesome.min.css'
import './App.css'
import React, { useEffect} from 'react'
import { BrowserRouter } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
//import { registrar } from '../store/config/register'
import { isAuthenticated } from '../store/config/auth'
import {localStorageExpires} from '../store/config/storage'
import '@deepdub/react-ok-tooltip/dist/tooltip.css';
import Logo from '../components/template/Logo'
import Footer from '../components/template/Footer'
import Routes from './Routes'



export default function App(){

useEffect(()=>{
    localStorageExpires();

},[])

 

  
    

        return (
             <BrowserRouter>
                <div id='app' className={isAuthenticated() ? '' :'hide-menu'}>
            <ToastContainer autoClose={3000} />
           { isAuthenticated() ?<Logo /> :undefined}
           {/* {  isAuthenticated() ?<Nav /> :undefined } */}
            <Routes />
            <Footer />
            </div>
         </BrowserRouter>) 
}


