export const AbrirPDFEmNovaAba = (data) =>{
    const raw = window.atob(data);
        const rawLength = raw.length;
        const blobArray = new Uint8Array(new ArrayBuffer(rawLength));

        for (let i = 0; i < rawLength; i++) {
            blobArray[i] = raw.charCodeAt(i);
        }

        const blob = new Blob([blobArray], {type: 'application/pdf'});

        const url =  window.URL.createObjectURL(blob)
        window.open(url, '_blank').focus();
  }