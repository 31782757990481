import './Logo.css'
import logo from '../../assets/imgs/logo.png'
import logo_granja from '../../assets/imgs/logo_granja_transparente.png'
import React,{useState} from 'react'
import { Link } from 'react-router-dom'
import Dropdown from 'react-bootstrap/Dropdown';
import { authKey } from '../../global';
import { useHistory } from 'react-router-dom';
import { user } from '../../store/config/dadosuser';
import { empresa_Id } from '../../store/config/dadosuser'

export default function Logo() {
     const history= new useHistory();
    const [clicked,setClicked] =useState(false);


   
     function logout(){
       localStorage.clear(authKey);
       history.push("/");
       window.location.reload();
     }
   
   
      function handleClick  () {
            setClicked(!clicked)
       }

    return (
        <aside className="logo">
         <div id="log" onClick={() =>handleClick()}>
            <Link to="/" className="logo">
            {empresa_Id == 1? (
                <img src={logo} alt="logo"/>
            ):(
                <img src={logo_granja} alt="logo"/>
            )}
                
            </Link>
           
          
            <div id='mobile' className='log'>
            <Dropdown>
                        <Dropdown.Toggle variant="primary" id="dropdown-basic">
                        {user.nome}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item className='dropdowitem' href="/">Home</Dropdown.Item>
                            <Dropdown.Item className='dropdowitem' onClick={()=>logout()}>Logout</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
            </div>
                    
                </div>
        </aside>
    );
}
