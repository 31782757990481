import CryptoJS from "crypto-js";
import { getLocalStorage } from "./storage";
import { authKey,secretKey } from "../../global";
import { buscaItemSessao } from "../../util/Sessao/BuscarItemSessao";

let emp =buscaItemSessao();

if(emp === undefined){
  emp= buscaItemSessao() === undefined ? undefined : JSON.parse(buscaItemSessao())
}else{
  emp=JSON.parse(emp.empresa)
}

export const user = getLocalStorage(authKey) === undefined ?  undefined : JSON.parse(getLocalStorage(authKey));
export const empresa = buscaItemSessao()  !== undefined ? DecryptEmpresa(emp) :undefined;
const data = user === undefined ? undefined : VerificaUsuario();
export const login_Id =  user === undefined ? undefined : parseInt(data.split('/')[0]);
export const empresa_Id =  user === undefined ? undefined : parseInt(data.split('/')[2]);

  function VerificaUsuario(){
    let bytes  = CryptoJS.AES.decrypt(user.Token, secretKey).toString(CryptoJS.enc.Utf8);
    let decryptedData = bytes.toString(CryptoJS.enc.Utf8);
    return decryptedData;    
  }

  function DecryptEmpresa(empresa){
    if(empresa !== null){
      let bytes  = CryptoJS.AES.decrypt(empresa.empresa, secretKey).toString(CryptoJS.enc.Utf8);
      let decryptedData = bytes.toString(CryptoJS.enc.Utf8);
      return decryptedData;  
    }
      
  }